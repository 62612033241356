import React, { useState, useEffect, useContext } from "react";
import "./main.css";
import "semantic-ui-css/semantic.min.css";
import Gmaps from "../components/Gmaps";
import { Layout, Typography, Space, List } from "antd";
import { MainContext } from "../contexts/MainContext";
import {
  legsTo2D,
  legsToList,
  routeDirections,
  decode,
  toLineStringSingle,
  splitArray,
} from "../utils/PolylineDecoder";
import Sidebar from "../components/Sidebar";
import SmapHeader from "../components/Header";
import Escalas from "../components/Escalas";
import Permisos from "../components/Drawer";
import Camiones from "../components/Camiones";
import MenuAdmin from "../components/MenuAdmin";
import { RutasContext } from "../contexts/RutasContext";
import { Api } from "../api";
import logoDimmer from "../images/bloqueos/logo_dimmer.png";
import botonDimmer from "../images/bloqueos/antp_MensajeSmapBloqueos_Boton.png";
import pinDimmer from "../images/bloqueos/antp_MensajeSmapBloqueos_pin.png";
import { useMatch } from "react-router-dom";
import styled from "styled-components";

import { ToolBar } from "../components/ToolBar";
import {
  Button,
  Dimmer,
  Header,
  Image,
  Segment,
  Grid,
} from "semantic-ui-react";
import Minimizable from "../components/Minimizable";

const { Content } = Layout;
const { Text, Title: T1 } = Typography;

const Title = styled(T1)`
  && {
    color: white;
  }
`;

const data = [
  {
    mensaje: (
      <Title level={5}>
        Estamos conscientes de que el origen de esta problematica no se ha
        resuelto, sin embargo, consideramos que las acciones como bloqueos,
        paros o limitacion de las operaciones logísticas y de transporte de
        carga en el país no contribuye a la solución.
      </Title>
    ),
  },
  {
    mensaje: (
      <Title level={5}>
        En esta sentido les recordamos que estaremos monitoreando y compartiendo
        con ustedes la información que se genere al respecto, para que las
        operaciones de logística y transporte no se vean afectadas, en
        cumplimiento a la regulación y apego a la legalidad. En casa de requerir
        datos sobre los lugares dónde existan bloqueos, pondremos a su
        disposición el mapa interactivo S-MAP en donde se estarán indicando los
        puntos en donde identifiqueos bloqueos o conflictos y así programar sus
        embarques y rutas.
      </Title>
    ),
  },
  {
    mensaje: (
      <Title level={5}>
        También, les pedimos su apoyo y nos compartan la información sobre la
        identificación de un bloqueo o afectación en los puntos carrerteros,
        para ello es necesario nos envién el nombre y kilómetro del tramo
        carretero y/o las coordenadas (longitud y latitud) , de ser posible, una
        fotografía del evento.
      </Title>
    ),
  },
];

export default function Main(props) {
  const {
    token,
    ferry,
    camion,
    setCamion,
    origen,
    destino,
    direcciones,
    setDirecciones,
    escalas,
    setEscalas,
    res,
    setRes,
    escalasTipos,
    escalasCamiones,
    dataPassed,
    setDataPassed,
    polygonPoints,
    setPolygonPoints
  } = useContext(MainContext);
  const { rutas, setRutas } = useContext(RutasContext);
  //const [polygonPoints, setPolygonPoints] = useState([]);
  //const [dataPassed, setDataPassed] = useState([]);
  const [routeArray, setRouteArray] = useState([]);
  const [routeDirs, setRouteDirs] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const { setCargandoRuta, cargandoRuta } = useContext(MainContext);

  const [dimmerBloqueos, setDimmerBloqueos] = useState(false);

  const isBloqueosRoute = useMatch({
    path: "/apps/bloqueos_carreteros",
    strict: true,
    sensitive: true,
  });

  const toLineString = (routes, inters, tramo) => {
    console.log("inters: ", inters);

    const lineString = {
      origen: inters[0],
      destino: inters[1],
      tramo: tramo + 1,
      tipo_escala: escalasTipos[tramo],
      camion: escalasCamiones[tramo] ?? camion,
      ruta: {
        type: "LineString",
      },
    };

    lineString.ruta["coordinates"] = routes.map((r) => [r.lng, r.lat]);

    return lineString;
  };

  useEffect(() => {
    console.log("cambio busqueeda");
    setRes(null);
  }, [origen, destino, escalas]);

  useEffect(() => {
    console.log("cambio direcciones", direcciones);
    if (direcciones) {
      try {
        console.log("res_directions: ", direcciones);

        const legs = direcciones.routes[0].legs;
        const polyList = legsToList(legs);
        const routeDir = routeDirections(legs);

        const tempW = [
          origen,
          ...escalas.map(({ location }) => location),
          destino,
        ];

        const routeArr = legsTo2D(legs);
        const lineStrings = getLineStrings(routeArr, tempW);

        console.log("rrr", rutas);
        setRutas({
          ...rutas,
          origen,
          destino,
          tramos: lineStrings,
        });
        setRouteDirs(routeDir);
        setPolygonPoints(polyList);
      } catch (err) {
        console.log(err);
      }
    } else { 
      setPolygonPoints([]);
    }
  }, [direcciones]);

  const showDrawer = () => {
    setDrawer(true);
  };

  const onClose = () => {
    setDrawer(false);
  };

  function importAll(r) {
    //console.log("r: ", r.keys());
    let o = [];
    let f = {};

    r.keys().forEach((k) => {
      o.push({
        name: k.substring(2, k.length - 5),
        checked: false,
      });
    });

    let data = r.keys().map(r);
    //console.log(data);
    data.map((d, i) => {
      o[i] = { data: d.features, ...o[i] };
    });
    o.forEach((ob) => {
      f[ob.name] = {
        checked: ob.checked,
        data: ob.data,
        markers: null,
        polys: null,
      };
    });
    //console.log(f);
    return f;
  }

  const filesObject = [];

  const parsePoints = (data) => {
    let markers = [];
    let polylines = [];
    let n;
    data.features.forEach((data_obj) => {
      //MARKER
      if (data_obj.geometry.type === "Point") {
        markers.push({
          lat: data_obj.geometry.coordinates[1],
          lng: data_obj.geometry.coordinates[0],
          name: data_obj.properties.Name,
          desc: data_obj.properties.description,
        });
      }
      //POLYLINE
      else {
        let route = [];
        data_obj.geometry.coordinates.forEach((coord) => {
          route.push([coord[1], coord[0]]);
        });
        let data = { name: data_obj.properties.name, properties: data_obj.properties, line: route };
        polylines.push(data);
      }
    });
    console.log(polylines);
    return { markers: markers, poly: polylines };
  };

  const handleToggle = async (props, id) => {
    console.log("props", props, id);
    switch (id) {
      case "rob-21-old":
        console.log("async load of markers");
        console.log(filesObject);

        const parser = await fetch(
          "https://spreadsheets.google.com/feeds/list/1Osis1bdU6Lx6Zbrb-LDUaKl0wGDPWrUYZsOuo5ZFvLQ/1/public/full?alt=json"
        );
        const data = await parser.json();
        const robos2021 = data.feed.entry;
        const robos2021map = robos2021.map((robo) => {
          let coordinates = robo.gsx$coordenadas.$t
            .split(",")
            .map((c) => Number(c))
            .reverse();

          const geometry = { type: "Point", coordinates };
          const description = `<strong>Fecha de los hechos:</strong> ${robo.gsx$fechadelreporte.$t}<br>
          <strong>Hora de los hechos:</strong> ${robo.gsx$horadelreporte.$t}<br><br>
          <strong>Hechos ocurridos:</strong> ${robo.gsx$hechos.$t}<br>
          <strong>Tipo de carga:</strong> ${robo.gsx$tipodemercancía.$t}<br><br>
          <strong>Tipo de vehículo:</strong> ${robo.gsx$tipodevehiculo.$t}<br>
          <strong>Placas de circulación:</strong> ${robo.gsx$placasdelvehiculo.$t}`;
          const properties = {
            Name: `${robo.gsx$hechos.$t} ${robo.gsx$fechadelreporte.$t}`,
            description,
          };
          return { geometry, properties, type: "Feature" };
        });

        //console.log(data.feed.entry);
        console.log(robos2021map);
        filesObject["rob-21-old"] = {
          checked: props,
          data: robos2021map,
          markers: null,
          polys: null,
        };
        break;
      default:
        if (props) {
          console.log("by:", props);
          const layer = await Api.Capas.getCapasByToken(id);
          filesObject[id] = {
            checked: props,
            data: layer,
            markers: null,
            polys: null,
          };
        }
    }

    if (props) {
      filesObject[id].checked = props;
      let res = parsePoints(filesObject[id].data);
      filesObject[id].markers = res.markers;
      filesObject[id].polys = res.polylines;
      let dataClone = [...dataPassed];
      dataClone.push({
        key: id,
        markers: res.markers,
        polyline: res.poly,
      });
      console.log("clone:", dataClone);
      setDataPassed(dataClone);
    } else {

      filesObject[id] = {
        checked: props,
        markers: null,
        polys: null,
      };

      let deleted = dataPassed.filter((v) => {
        return v.key !== id;
      });
      setDataPassed(deleted);
    }
  };

  const getLineStrings = (routeArray, tempEscalas) => {
    const routeA = routeArray.map((r) => r.map(decode).flat());
    const escalas = splitArray(tempEscalas);
    const lineStrings = routeA.map((r, i) => toLineString(r, escalas[i], i));
    return lineStrings;
  };

  const getLineString = (routeArray, origen, destino) => {
    const routeA = routeArray.map(decode).flat();
    console.log(routeA);
    const lineStrings = toLineStringSingle(routeA, origen, destino, 0);
    return lineStrings;
  };

  const clearFilters = () => {
    console.log('clear filters');
    setPolygonPoints([]);
    setDataPassed([]);
  };

  return (
    <Layout className="layout-style">
      <SmapHeader />

      <Layout>
        <Sidebar
          width={300}
          handleToggle={handleToggle}
          clearFilters={clearFilters}
          showDrawer={showDrawer}
          onClose={onClose}
        />
        <Layout>
          <ToolBar></ToolBar>
          <Dimmer.Dimmable as={Content}>
            <Gmaps
              google={props.google}
              //routeSearched={polygonPoints}
              //data={dataPassed}
              //routeArray={routeArray}
            />

            <MenuAdmin />

            {polygonPoints.length > 0 && token && rutas && (
              <Minimizable x={window.innerWidth / 1.4} y={20} text={"PERMISOS"}>
                <Permisos
                  onClose={onClose}
                  showDrawer={showDrawer}
                  drawer={drawer}
                  routeDirs={routeDirs}
                  origen={origen}
                  destino={destino}
                />
              </Minimizable>
            )}

            {polygonPoints.length > 0 && rutas && (
              <Minimizable x={window.offset / 1.55} y={20} text={"ESCALAS"}>
                <Escalas />
              </Minimizable>
            )}
            {polygonPoints.length > 0 && rutas && escalas.length == 0 && (
              <Minimizable
                x={window.innerWidth / 1.75}
                y={20}
                text={"VEHICULO"}
              >
                <Camiones />
              </Minimizable>
            )}
            {isBloqueosRoute && (
              <Dimmer
                active={!dimmerBloqueos}
                onClickOutside={() => {
                  setDimmerBloqueos(true);
                }}
                verticalAlign="center"
                onClick={() => {
                  setDimmerBloqueos(true);
                }}
              >
                <Grid divided="vertically">
                  <Grid.Row columns={2}>
                    <Grid.Column verticalAlign="middle" textAlign="center">
                      <Space>
                        <Image size="medium" src={logoDimmer} />
                      </Space>
                    </Grid.Column>
                    <Grid.Column verticalAlign="top" textAlign="left">
                      <Space direction="left">
                        <Title level={2}>Distinguidos socios</Title>
                      </Space>
                      <Space direction="left">
                        <Title level={5}>
                          Continuando con la convocatoria de algunas
                          organizaciones del autotransporte a un paro de
                          operación o evitar circular en las principales
                          carreteras del país, mañana martes 2 de marzo de 2021,
                          les comunicamos lo siguiente:
                        </Title>
                      </Space>

                      <List
                        itemLayout="vertical"
                        dataSource={data}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={<Image size="mini" src={pinDimmer} />}
                              description={item.mensaje}
                            />
                          </List.Item>
                        )}
                      />
                      <Image
                        size="medium"
                        src={botonDimmer}
                        onClick={() => {
                          setDimmerBloqueos(true);
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Dimmer>
            )}
          </Dimmer.Dimmable>
        </Layout>
      </Layout>
    </Layout>
  );
}
