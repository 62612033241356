import { useState, useContext } from "react";
import {
  Layout,
  Form,
  Button,
  message,
  Row,
  Col,
  Avatar,
  Input,
  Popconfirm,
  Checkbox,
  Spin,
  Space,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { UserOutlined } from "@ant-design/icons";
import WinBox from "react-winbox";

import { MainContext } from "../contexts/MainContext";
import { RutasContext } from "../contexts/RutasContext";
import { Rnd } from "react-rnd";
import Api from "../api";
import { Buscar } from "./Buscar";

import IAWizard from "./ia/IAWizard";
import LangflowChat from './ia/LangflowChat';
import ChatComponent from "./ia/ChatComponent";

const { Header } = Layout;

const SearchButton = styled(Button)`
  margin-right: 10px;
`;

const HeaderStyled = styled(Header)`
  background: #002140;
`;

// Styled WinBox
const StyledWinBox = styled(WinBox)`
  background-color: #001529;
  border-radius: 8px;
  width: 300px;
  @media (max-width: 768px) {
    width: 90%;
    max-width: 350px;
  }
`;

// Styled Form
const StyledForm = styled(Form)`
  padding: 20px;
  background-color: #002140;
`;

// Styled Form Item
const StyledFormItem = styled(Form.Item)`
  margin-bottom: 15px;
`;

const RndStyled = styled(Rnd)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 15px;
  background: #002140;
  border-radius: 5px;
  box-shadow: 1px 1px 5px black;
  z-index: 99999;
`;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export const SmapHeader = (props) => {
  const { token, setToken } = useContext(MainContext);
  const { ferry, setFerry } = useContext(MainContext);
  const {
    origen,
    destino,
    setOrigen,
    setDestino,
    setEscalas,
    cargandoRuta,
    setCargandoRuta,
    setDirecciones,
    setRes,
    setMapsProps,
    mostrarMenu,
    setMostrarMenu,
    setDataPassed,
  } = useContext(MainContext);
  const { rutas, setRutas } = useContext(RutasContext);
  const [isLogin, setIslogin] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState(true);
  const [oauto, setOauto] = useState(origen || "");
  const [dauto, setDauto] = useState(destino || "");

  const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);

  const [windows, setWindows] = useState({});

  const handleLogin = async (values) => {
    try {
      const response = await Api.Auth.login(values);
      console.log(response);
      if (response.token) {
        setToken(response.token);
        message.success("Bienvenido.");
        setIsLoginFormVisible(false);
      } else {
        message.error("Credenciales incorrectas.");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error("Error en la solicitud: Datos no válidos.");
      } else {
        message.error("Error en el servidor, intenta más tarde.");
      }
    }
  };

  const onFinish = async (values) => {
    try {
      const { token } = await Api.Auth.login(values);
      setToken(token);
      message.success("Bienvenido.");
      setIslogin(false);
    } catch (e) {
      message.error("Hubo un error con tus accesos. Trata de nuevo");
    }
  };

  const onBuscar = () => {
    setOrigen(oauto);
    setDestino(dauto);

    setDirecciones(null);
    setCargandoRuta(true);
  };

  const onLogout = () => {
    setToken(undefined);
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Verifica tu informacion");
  };

  const clickMenu = () => {
    setMostrarMenu(!mostrarMenu);
  };

  const openIAWindow = () => {
    const key = "ia_window";
    if (windows[key]) return; // Prevent duplicate windows

    const newWindow = (
      <WinBox
        title="IA"
        key={key}
        className="modern"
        x="center"
        y="center"
        width={550}
        height={850}
        onClose={() => closeWindow(key)}
      >
        <ChatComponent />
      </WinBox>
    );

    setWindows({ ...windows, [key]: newWindow });
  };

  const closeWindow = (key) => {
    const updatedWindows = { ...windows };
    delete updatedWindows[key];
    setWindows(updatedWindows);
  };

  return (
    <HeaderStyled>
      {isLogin && (
        <RndStyled
          default={{
            x: window.innerWidth / 2 - 250 / 2,
            y: window.innerHeight / 2 - 300 / 2,
            width: 250,
            height: "auto",
          }}
          bounds="window"
        >
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Introduce tu correo electronico!" },
              ]}
            >
              <Input placeholder="Correo Electronico" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: "Introduce tu contraseña!" }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </RndStyled>
      )}

      {isLoginFormVisible && (
        <StyledWinBox
          title="Iniciar Sesión"
          height="216"
          x="center"
          y="center"
          noFull
          noResize
          noMax
          onClose={() => setIsLoginFormVisible(false)}
        >
          <StyledForm onFinish={handleLogin} layout="vertical">
            <StyledFormItem
              name="email"
              rules={[
                {
                  required: true,
                  message: "Por favor ingresa tu correo electrónico",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Correo Electrónico"
              />
            </StyledFormItem>
            <StyledFormItem
              name="password"
              rules={[
                { required: true, message: "Por favor ingresa tu contraseña" },
              ]}
            >
              <Input.Password placeholder="Contraseña" />
            </StyledFormItem>
            <StyledFormItem>
              <Button type="primary" htmlType="submit" loading={loadingLogin}>
                Iniciar Sesión
              </Button>
            </StyledFormItem>
          </StyledForm>
        </StyledWinBox>
      )}

      <Row wrap={false}>
        <Col span={1}>
          <img alt="smap" style={{ height: "37px" }} src={"/favicon.png"} />
        </Col>
        <Col span={11}>
          <Spin tip="Cargando ruta..." spinning={cargandoRuta}>
            <Space direction="horizontal">
              {inputs && (
                <Buscar
                  placeholder={"Origen"}
                  value={oauto}
                  onCoordinatesChanged={({ place }) =>
                    setOauto(place?.formatted_address)
                  }
                />
              )}

              {inputs && (
                <Buscar
                  placeholder={"Destino"}
                  value={dauto}
                  onCoordinatesChanged={({ place }) => {
                    console.log(place);
                    setDauto(place?.formatted_address);
                  }}
                />
              )}
              {!dauto}
              <SearchButton
                loading={loading}
                disabled={!oauto || !dauto}
                type="primary"
                icon={<SearchOutlined />}
                onClick={onBuscar}
              >
                Buscar
              </SearchButton>
            </Space>
          </Spin>
        </Col>
        <Col span={6}>
          <Checkbox
            checked={ferry}
            onChange={(a) => setFerry(a.target.checked)}
          >
            Usar Ferries
          </Checkbox>
          {rutas && (
            <Button
              loading={loading}
              type="primary"
              danger
              onClick={() => {
                setInputs(false);
                setRutas();
                setRes(null);
                setOrigen(null);
                setDestino(null);
                setEscalas([]);
                setDirecciones();
                setFerry(false);

                setOauto("");
                setDauto("");
                setInputs(true);
                setMapsProps({
                  center: {
                    lat: 20.522593618909205,
                    lng: -99.89245980249002,
                  },
                  zoom: 5,
                });
              }}
            >
              Borrar Busqueda
            </Button>
          )}
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={openIAWindow}>
            IA
          </Button>
        </Col>
        <Col span={4}>
          {token ? (
            <>
              <Popconfirm
                title="¿Estas seguro que quieres salir?"
                onConfirm={onLogout}
                okText="Si"
                cancelText="No"
              >
                <Button type="primary" danger>
                  Salir
                </Button>
              </Popconfirm>{" "}
              <Button
                type="primary"
                onClick={() => clickMenu()}
                style={{ margin: "0px 5px" }}
              >
                Menu
              </Button>
              <Avatar
                style={{
                  verticalAlign: "middle",
                }}
              >
                user
              </Avatar>
            </>
          ) : (
            <Button type="primary" onClick={() => setIsLoginFormVisible(true)}>
              Login
            </Button>
          )}
        </Col>
      </Row>
      {Object.values(windows)}
    </HeaderStyled>
  );
};

export default SmapHeader;
