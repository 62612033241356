import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import { MdMap, MdClear } from "react-icons/md";
import styled from "styled-components";

export const Buscar = ({ placeholder, onCoordinatesChanged, onChange, onMapIconClick, value: initialValue }) => {
  const [value, setValue] = useState(initialValue || "");
  const [predictions, setPredictions] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    console.log("changed value", initialValue)
    setValue(initialValue?.address ?? initialValue);
   }, [initialValue])

  const handleInputChange = (e) => {
    const input = e.target.value;
    setValue(input);

    if (autocompleteService && input) {
      autocompleteService.getPlacePredictions(
        {
          input,
          types: ["(regions)"],
          componentRestrictions: { country: "mx" },
        },
        (predictions) => {
          setPredictions(predictions);
          setDropdownVisible(true);
        }
      );
    } else {
      setPredictions([]);
      setDropdownVisible(false);
    }
  };

  const handleSelect = (prediction) => {
    if (!placesService) return;
  
    placesService.getDetails({ placeId: prediction.place_id }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if (!place.geometry) return;
  
        const { lat, lng } = place.geometry.location;
        const address = place.formatted_address;
        setSelectedPlace(place);
        setValue(address);
        setDropdownVisible(false);
  
        onCoordinatesChanged?.({
          place,
          address,
          coordinates: { lat: lat(), lng: lng() },
        });
        onChange?.({
          place,
          address,
          coordinates: { lat: lat(), lng: lng() },
        });
      }
    });
  };
  

  const handleClear = () => {
    setValue("");
    setSelectedPlace(null);
    setDropdownVisible(false);
    onCoordinatesChanged?.({ place:null, address: "", coordinates: null });
    onChange?.({ place:null, address: "", coordinates: null });
  };

  const handleMapIconClick = () => {
    onMapIconClick(selectedPlace);
  };

  useEffect(() => {
    if (window.google) {
      setAutocompleteService(new window.google.maps.places.AutocompleteService());
      setPlacesService(new window.google.maps.places.PlacesService(document.createElement('div')));
    }
  }, []);

  const handleDocumentClick = (e) => {
    if (inputRef.current && !inputRef.current.contains?.(e.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        ref={(input) => {
          inputRef.current = input;
        }}
        suffix={<MapIcon value={value} onClick={handleClear} onMapIconClick={handleMapIconClick} />}
      />
      {dropdownVisible && (
        <AutocompleteDropdown>
          {predictions?.map((prediction) => (
            <AutocompleteItem
              key={prediction.place_id}
              onClick={() => handleSelect(prediction)}
            >
              {prediction.description}
            </AutocompleteItem>
          ))}
        </AutocompleteDropdown>
      )}
      
    </div>
  );
};

const MapIcon = ({ value, onClick, onMapIconClick }) => {
  return (
    <span style={{ cursor: 'pointer' }}>
      {value && <MdClear onClick={onClick} />}
      <MdMap onClick={onMapIconClick} />
    </span>
  );
};

const AutocompleteDropdown = styled.div`
  background-color: #144272;
  position: fixed;
  z-index: 100;
  width: ${(props) => props.width}px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
`;

const AutocompleteItem = styled.div`
  line-height: 32px;
  padding: 0 10px ; 
  cursor: pointer;
  &:hover {
    background-color: #2C74B3;
  }
`;
