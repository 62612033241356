import { PolyUtil } from "node-geometry-library";

function getBoundingBox(polyCoord) {
  //Lat
  let max = polyCoord[0].lat;
  let min = polyCoord[0].lat;

  polyCoord.forEach((coord) => {
    coord.lat > max && (max = coord.lat);
    coord.lat < min && (min = coord.lat);
  });

  //Long
  let rightLng = polyCoord[0].lng;
  let leftLng = polyCoord[0].lng;

  polyCoord.forEach((coord) => {
    Math.abs(coord.lng) > rightLng
      ? (rightLng = coord.lng)
      : (rightLng = rightLng);
    Math.abs(coord.lng) < leftLng ? (leftLng = coord.lng) : (leftLng = leftLng);
  });

  if (rightLng < leftLng) rightLng = [leftLng, (leftLng = rightLng)][0];

  return {
    topL: { lat: max, lng: leftLng },
    topR: { lat: max, lng: rightLng },
    botL: { lat: min, lng: leftLng },
    botR: { lat: min, lng: rightLng },
  };
}

function narrowMarkers(box, markersCoord) {
  if (markersCoord) {
    let narrowedMarkers = [];

    markersCoord.forEach((coord) => {
      if (
        coord.lat <= box.topL.lat &&
        coord.lat >= box.botR.lat &&
        coord.lng >= box.topL.lng &&
        coord.lng <= box.botR.lng
      )
        narrowedMarkers.push(coord);
    });
    return narrowedMarkers;
  }
}

export function checkCoordinates(polyCoord, markersCoord) {
  //console.log(polyCoord, markersCoord)
  let box = getBoundingBox(polyCoord);

  let newMarkers = narrowMarkers(box, markersCoord);

  let pCList = [];
  polyCoord.forEach((pc) => pCList.push({ lat: pc.lat, lng: pc.lng }));

  let finalMarkers = [];

  newMarkers.forEach((marker) => {
    let point = { lat: marker.lat, lng: marker.lng };
    if (PolyUtil.isLocationOnPath(point, pCList, 5000))
      finalMarkers.push(marker);
  });

  return finalMarkers;
}
