import React, { useState, useEffect, useRef } from "react";
import { Input, Button, List, Typography } from "antd";

const { TextArea } = Input;

const LangflowChat = ({flow = { id: "471709ca-c25f-4efc-bc74-e899989dba60" }}) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [lockChat, setLockChat] = useState(false);
  const ws = useRef(null);
  const id = useRef(flow.id);


  function handleOnClose(event: CloseEvent) {
    //if (isOpen.current) {
      console.log({ title: event.reason });
      setTimeout(() => {
        connectWS();
        setLockChat(false);
      }, 1000);
    //}
  }

  useEffect(() => {
    if (
      ws.current &&
      (ws.current.readyState === ws.current.CLOSED ||
        ws.current.readyState === ws.current.CLOSING)
    ) {
      connectWS();
      setLockChat(false);
    }
  }, [lockChat]);

  function connectWS() {
    try {
      const urlWs =
        process.env.NODE_ENV === "development"
          ? `ws://langflow.antp.org.mx/api/v1/chat/${id.current}?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzMThkMDc3Yy0wMTRjLTQ0NTQtYThmYi0wN2UxYjBjMTQwMmYiLCJleHAiOjE3NDk0OTA4MjV9.0zDUnw4ArUPTY1njTJOAAajox1azfmPZ9YWEJMElea0`
          : `${window.location.protocol === "https:" ? "wss" : "ws"}://${
              window.location.host
            }api/v1/chat/${id.current}?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzMThkMDc3Yy0wMTRjLTQ0NTQtYThmYi0wN2UxYjBjMTQwMmYiLCJleHAiOjE3NDk0OTA4MjV9.0zDUnw4ArUPTY1njTJOAAajox1azfmPZ9YWEJMElea0`;
      const newWs = new WebSocket(urlWs);
      newWs.onopen = () => {
        console.log("WebSocket connection established!");
      };
      newWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received data:", data);
        //handleWsMessage(data);
        //get chat history
      };
      newWs.onclose = (event) => {
        handleOnClose(event);
      };
      newWs.onerror = (ev) => {
        console.log(ev, "error");
        if (flow.id === "") {
          connectWS();
        } else {
          console.error({
            title: "There was an error on web connection, please: ",
            list: [
              "Refresh the page",
              "Use a new flow tab",
              "Check if the backend is up",
            ],
          });
        }
      };
      ws.current = newWs;
    } catch (error) {
      if (flow.id === "") {
        connectWS();
      }
      console.log(error);
    }
  }


  useEffect(() => {
    connectWS();

    // Cleanup on component unmount
    return () => {
      console.log(ws);
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  async function sendAll(data) {
    try {
      if (ws) {
        ws.current.send(JSON.stringify(data));
      }
    } catch (error) {
      console.log({
        title: "There was an error sending the message",
        list: [error.message],
      });
      console.log(data.message);
      connectWS();
    }
  }

  const sendMessage = () => {
    if (input.trim() !== "") {
      const message = { content: input, type: "user" };
      sendAll(message)
      //ws.current.send(JSON.stringify(message));
      setMessages((prevMessages) => [...prevMessages, message]);
      setInput("");
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <Typography.Title level={4}>Langflow Chat</Typography.Title>
      <List
        bordered
        dataSource={messages}
        renderItem={(message) => (
          <List.Item
            style={{ textAlign: message.type === "user" ? "right" : "left" }}
          >
            <List.Item.Meta
              title={message.type === "user" ? "You" : "Bot"}
              description={message.content}
            />
          </List.Item>
        )}
        style={{ marginBottom: "20px", maxHeight: "400px", overflowY: "auto" }}
      />
      <TextArea
        rows={4}
        value={input}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Type a message..."
        style={{ marginBottom: "10px" }}
      />
      <Button type="primary" onClick={sendMessage} block>
        Send
      </Button>
    </div>
  );
};

export default LangflowChat;
