import React, { useState, useEffect } from "react";
import { Api } from "../../api"; // Adjust the path to match your API module
import { GoogleMap, Marker } from "@react-google-maps/api";
import {
  Modal,
  Form,
  Input,
  Button,
  DatePicker,
  TimePicker,
  InputNumber,
  Table,
  Checkbox,
  Space,
  notification,
  Select,
} from "antd";
import WKT from "wkt";
import dayjs from "dayjs";

dayjs.locale("es");

const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

const defaultCenter = { lat: 23.634501, lng: -102.552784 };

const InformeDeRobosAdmin = () => {
  const [informes, setInformes] = useState([]);
  const [editingInforme, setEditingInforme] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editForm] = Form.useForm();
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [addForm] = Form.useForm();
  const [estados, setEstados] = useState([]);
  const [tiposDeMercancia, setTiposDeMercancia] = useState([]);
  const [hechos, setHechos] = useState([]);
  const [tiposDeVehiculo, setTiposDeVehiculo] = useState([]);
  const [desenlaces, setDesenlaces] = useState([]);

  const [addMarkerPosition, setAddMarkerPosition] = useState(defaultCenter);
  const [editMarkerPosition, setEditMarkerPosition] = useState(defaultCenter);

  useEffect(() => {
    loadEnums();
    loadInformes();
  }, []);

  const loadEnums = async () => {
    try {
      const fetchedEstados = await Api.Enums.Estados.getAll();
      setEstados(fetchedEstados);
      const fetchedTiposDeMercancia = await Api.Enums.TipoDeMercancias.getAll();
      setTiposDeMercancia(fetchedTiposDeMercancia);
      const fetchedHechos = await Api.Enums.Hechos.getAll();
      setHechos(fetchedHechos);
      const fetchedTiposDeVehiculo = await Api.Enums.TipoDeVehiculos.getAll();
      setTiposDeVehiculo(fetchedTiposDeVehiculo);
      const fetchedDesenlaces = await Api.Enums.Desenlaces.getAll();
      setDesenlaces(fetchedDesenlaces);
    } catch (error) {
      console.error("Error loading enums:", error);
      notification.error({
        message: "Error cargando datos",
        description:
          "No se pudieron cargar los datos necesarios para los formularios.",
      });
    }
  };

  const notifySuccess = (message) => {
    notification.success({
      message: message,
      description: "",
    });
  };

  const notifyError = (error) => {
    notification.error({
      message: "Error",
      description: error.toString(),
    });
  };

  const loadInformes = async () => {
    try {
      const fetchedInformes = await Api.InformesDeRobos.getAll();
      setInformes(fetchedInformes);
    } catch (error) {
      console.error("Error loading informes:", error);
      notification.error({
        message: "Error cargando informes",
        description: "No se pudieron cargar los informes de robo.",
      });
    }
  };

  const onAddMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setAddMarkerPosition({ lat, lng });
    addForm.setFieldsValue({ latitud: lat, longitud: lng });
  };

  // Function to update marker position from the map click, used in edit modal
  const onEditMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setEditMarkerPosition({ lat, lng });
    editForm.setFieldsValue({ latitud: lat, longitud: lng });
  };

  const handleDelete = async (id) => {
    try {
      // Llama a la API para eliminar el informe por id
      await Api.InformesDeRobos.delete(id);
      // Muestra una notificación de éxito
      notification.success({
        message: "Informe Eliminado",
        description: "El informe ha sido eliminado exitosamente.",
      });
      // Refresca la lista de informes
      loadInformes();
    } catch (error) {
      // Muestra una notificación de error
      notification.error({
        message: "Error al Eliminar el Informe",
        description: "Hubo un error al eliminar el informe.",
      });
    }
  };

  const showDeleteConfirm = (id) => {
    Modal.confirm({
      title: "¿Estás seguro de eliminar este informe?",
      content: "Esta acción no se puede deshacer.",
      okText: "Sí, eliminar",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleAdd = async () => {
    try {
      const values = await addForm.validateFields();

      const formattedDate =
        values.fecha_del_reporte &&
        values.fecha_del_reporte.format("YYYY-MM-DD");
      const formattedTime =
        values.hora_del_reporte && values.hora_del_reporte.format("HH:mm");

      const response = await Api.InformesDeRobos.create({
        ...values,
        fecha_del_reporte: formattedDate,
        hora_del_reporte: formattedTime,
        latitud: addMarkerPosition.lat,
        longitud: addMarkerPosition.lng,
      });

      if (response.ok) {
        notifySuccess("Informe de robo agregado exitosamente");
        setIsAddModalVisible(false);
        addForm.resetFields();
        setAddMarkerPosition(defaultCenter);
        loadInformes();
      } else {
        throw new Error(
          response.problem || "Error al agregar el informe de robo"
        );
      }
    } catch (error) {
      notifyError(error.message || "Error al agregar el informe de robo");
      console.error("Error al agregar nuevo informe:", error);
    }
  };

  const showEditModal = (informe) => {
    setEditingInforme(informe);
    let pathGeoJson = WKT.parse(informe.geom_text);
    const [lng, lat] = pathGeoJson?.coordinates;
    setEditMarkerPosition({ lat, lng });
    editForm.setFieldsValue({
      ...informe,
      fecha_del_reporte: dayjs(informe.fecha_del_reporte),
      hora_del_reporte: dayjs(
        `2021-01-01 ${informe.hora_del_reporte}`,
        "YYYY-MM-DD HH:mm"
      ),
      latitud: lat,
      longitud: lng,
    });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingInforme(null);
  };

  const handleEdit = async () => {
    try {
      const values = await editForm.validateFields();

      const response = await Api.InformesDeRobos.update(editingInforme.id, {
        ...values,
        fecha_del_reporte: values.fecha_del_reporte.format("YYYY-MM-DD"),
        hora_del_reporte: values.hora_del_reporte.format("HH:mm"),
      });

      if (response.ok) {
        notifySuccess("Informe de robo actualizado exitosamente");
        setIsModalVisible(false);
        loadInformes();
      } else {
        throw new Error(
          response.problem || "Error al actualizar el informe de robo"
        );
      }
    } catch (error) {
      notifyError(error.message || "Error al actualizar el informe de robo");
      console.error("Error updating informe:", error);
    }
  };

  const columns = [
    {
      title: "Fecha del Reporte",
      dataIndex: "fecha_del_reporte",
      key: "fecha_del_reporte",
    },
    {
      title: "Hora del Reporte",
      dataIndex: "hora_del_reporte",
      key: "hora_del_reporte",
    },
    {
      title: "Empresa",
      dataIndex: "empresa",
      key: "empresa",
    },
    {
      title: "Persona que Reporta",
      dataIndex: "persona_que_reporta",
      key: "persona_que_reporta",
    },
    {
      title: "Email de la Persona que Reporta",
      dataIndex: "email_de_la_persona_que_reporta",
      key: "email_de_la_persona_que_reporta",
    },
    {
      title: "Teléfono de la Persona que Reporta",
      dataIndex: "telefono_de_la_persona_que_reporta",
      key: "telefono_de_la_persona_que_reporta",
    },
    {
      title: "Estado del Robo",
      key: "estado_del_robo",
      render: (_, record) => record.estadoDelRobo?.nombre || "No especificado",
    },
    {
      title: "Tipo de Mercancía",
      key: "tipo_de_mercancia",
      render: (_, record) =>
        record.tipoDeMercancia?.nombre || "No especificado",
    },
    {
      title: "Tipo de Vehículo",
      key: "tipo_de_vehiculo",
      render: (_, record) => record.tipoDeVehiculo?.nombre || "No especificado",
    },
    {
      title: "Placas del Vehículo",
      dataIndex: "placas_del_vehiculo",
      key: "placas_del_vehiculo",
    },
    {
      title: "Hechos",
      key: "hechos",
      render: (_, record) => record.hechos?.nombre || "No especificado",
    },
    {
      title: "Desenlace",
      key: "desenlace",
      render: (_, record) => record.desenlace?.nombre || "No especificado",
    },
    {
      title: "Observaciones",
      dataIndex: "observaciones",
      key: "observaciones",
    },
    {
      title: "Enlaces de Imágenes",
      dataIndex: "enlaces_de_imagenes",
      key: "enlaces_de_imagenes",
    },
    {
      title: "Es Visible",
      dataIndex: "es_visible",
      key: "es_visible",
      render: (text, record) => (record.es_visible ? "Sí" : "No"),
    },
    {
      title: "Acciones",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => showEditModal(record)}>Editar</Button>
          <Button onClick={() => showDeleteConfirm(record.id)} type="danger">
            Eliminar
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Button
        type="primary"
        onClick={() => setIsAddModalVisible(true)}
        style={{ marginBottom: 16 }}
      >
        Agregar Reporte de Robo
      </Button>

      <Table
        size="small"
        indentSize="small"
        dataSource={informes}
        columns={columns}
        rowKey="id"
      />

      <Modal
        title="Editar Informe"
        open={isModalVisible}
        onOk={() => handleEdit(editingInforme.id)}
        onCancel={handleCancel}
        okText="Actualizar"
        cancelText="Cancelar"
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            name="fecha_del_reporte"
            label="Fecha del Reporte"
            rules={[
              {
                required: true,
                message: "Por favor ingrese la fecha del reporte",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="hora_del_reporte"
            rules={[
              {
                required: true,
                message: "Por favor ingrese la hora del reporte",
              },
            ]}
            label="Hora del Reporte"
          >
            <TimePicker format="HH:mm" />
          </Form.Item>
          <Form.Item
            name="empresa"
            label="Empresa"
            rules={[
              { required: true, message: "Por favor ingrese la empresa" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="persona_que_reporta"
            label="Persona Que Reporta"
            rules={[
              {
                required: true,
                message: "Por favor ingrese la persona que reporta",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email_de_la_persona_que_reporta"
            label="Email de la Persona Que Reporta"
            rules={[
              {
                type: "email",
                message: "Por favor ingrese un correo electrónico válido",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="telefono_de_la_persona_que_reporta"
            label="Teléfono de la Persona Que Reporta"
            rules={[
              {
                message:
                  "Por favor ingrese el teléfono de la persona que reporta",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="estado_del_robo_id"
            label="Estado del Robo"
            rules={[
              {
                required: true,
                message: "Por favor seleccione el estado del robo",
              },
            ]}
          >
            <Select placeholder="Seleccione el estado del robo">
              {estados.map((estado) => (
                <Select.Option key={estado.id} value={estado.id}>
                  {estado.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="tipo_de_mercancia_id"
            label="Tipo de Mercancía"
            rules={[
              {
                required: true,
                message: "Por favor seleccione el tipo de mercancía",
              },
            ]}
          >
            <Select placeholder="Seleccione el tipo de mercancía">
              {tiposDeMercancia.map((tipo) => (
                <Select.Option key={tipo.id} value={tipo.id}>
                  {tipo.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="tipo_de_vehiculo_id" label="Tipo de Vehículo">
            <Select placeholder="Seleccione el tipo de vehículo">
              {tiposDeVehiculo.map((tipo) => (
                <Select.Option key={tipo.id} value={tipo.id}>
                  {tipo.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="placas_del_vehiculo" label="Placas del Vehículo">
            <Input />
          </Form.Item>
          <Form.Item
            name="hechos_id"
            label="Hechos"
            rules={[
              { required: true, message: "Por favor seleccione los hechos" },
            ]}
          >
            <Select placeholder="Seleccione los hechos">
              {hechos.map((hecho) => (
                <Select.Option key={hecho.id} value={hecho.id}>
                  {hecho.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="desenlace_id" label="Desenlace">
            <Select placeholder="Seleccione el desenlace">
              {desenlaces.map((desenlace) => (
                <Select.Option key={desenlace.id} value={desenlace.id}>
                  {desenlace.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="observaciones" label="Observaciones">
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item name="enlaces_de_imagenes" label="Enlaces de Imágenes">
            <Input />
          </Form.Item>
          <Form.Item
            name="es_visible"
            label="Es Visible"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="Latitud"
            name="latitud"
            rules={[
              {
                required: true,
                message: "Por favor ingrese latitud",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Longitud"
            name="longitud"
            rules={[
              {
                required: true,
                message: "Por favor ingrese longitud",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <div style={mapContainerStyle}>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={5}
              center={editMarkerPosition}
            >
              <Marker
                position={editMarkerPosition}
                draggable={true}
                onDragEnd={onEditMarkerDragEnd}
              />
            </GoogleMap>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Agregar Nuevo Informe de Robo"
        open={isAddModalVisible}
        onOk={handleAdd}
        onCancel={() => setIsAddModalVisible(false)}
        okText="Agregar"
        cancelText="Cancelar"
      >
        <Form form={addForm} layout="vertical">
          <Form.Item
            name="fecha_del_reporte"
            label="Fecha del Reporte"
            rules={[
              {
                required: true,
                message: "Por favor seleccione la fecha del informe",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="hora_del_reporte"
            label="Hora del Reporte"
            rules={[
              {
                required: true,
                message: "Por favor seleccione la hora del informe",
              },
            ]}
          >
            <TimePicker format="HH:mm" />
          </Form.Item>
          <Form.Item
            name="empresa"
            label="Empresa"
            rules={[
              {
                required: true,
                message: "Por favor ingrese el nombre de la empresa",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="persona_que_reporta"
            label="Persona Que Reporta"
            rules={[
              {
                required: true,
                message: "Por favor ingrese la persona que reporta",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email_de_la_persona_que_reporta"
            label="Email de la Persona Que Reporta"
            rules={[
              {
                type: "email",
                message: "Por favor ingrese un correo electrónico válido",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="telefono_de_la_persona_que_reporta"
            label="Teléfono de la Persona Que Reporta"
            rules={[
              {
                message:
                  "Por favor ingrese el teléfono de la persona que reporta",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="estado_del_robo_id"
            label="Estado del Robo"
            rules={[
              {
                required: true,
                message: "Por favor seleccione el estado del robo",
              },
            ]}
          >
            <Select placeholder="Seleccione el estado del robo">
              {estados.map((estado) => (
                <Select.Option key={estado.id} value={estado.id}>
                  {estado.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="tipo_de_mercancia_id"
            label="Tipo de Mercancía"
            rules={[
              {
                required: true,
                message: "Por favor seleccione el tipo de mercancía",
              },
            ]}
          >
            <Select placeholder="Seleccione el tipo de mercancía">
              {tiposDeMercancia.map((tipo) => (
                <Select.Option key={tipo.id} value={tipo.id}>
                  {tipo.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="tipo_de_vehiculo_id" label="Tipo de Vehículo">
            <Select placeholder="Seleccione el tipo de vehículo">
              {tiposDeVehiculo.map((tipo) => (
                <Select.Option key={tipo.id} value={tipo.id}>
                  {tipo.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="placas_del_vehiculo" label="Placas del Vehículo">
            <Input />
          </Form.Item>
          <Form.Item
            name="hechos_id"
            label="Hechos"
            rules={[
              { required: true, message: "Por favor seleccione los hechos" },
            ]}
          >
            <Select placeholder="Seleccione los hechos">
              {hechos.map((hecho) => (
                <Select.Option key={hecho.id} value={hecho.id}>
                  {hecho.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="desenlace_id" label="Desenlace">
            <Select placeholder="Seleccione el desenlace">
              {desenlaces.map((desenlace) => (
                <Select.Option key={desenlace.id} value={desenlace.id}>
                  {desenlace.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="observaciones" label="Observaciones">
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item name="enlaces_de_imagenes" label="Enlaces de Imágenes">
            <Input />
          </Form.Item>
          <Form.Item
            name="es_visible"
            label="Es Visible"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="Latitud"
            name="latitud"
            rules={[
              {
                required: true,
                message: "Por favor ingrese latitud",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Longitud"
            name="longitud"
            rules={[
              {
                required: true,
                message: "Por favor ingrese longitud",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <div style={mapContainerStyle}>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={5}
              center={addMarkerPosition}
            >
              <Marker
                position={addMarkerPosition}
                draggable={true}
                onDragEnd={onAddMarkerDragEnd}
              />
            </GoogleMap>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default InformeDeRobosAdmin;
