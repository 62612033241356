import React, { useState } from "react";
import styled from "styled-components";
import { Avatar } from "antd";
import { Rnd } from "react-rnd";
import { FaWindowMinimize } from "react-icons/fa";
import { Tabs, Space } from "antd";

const AvatarRnd = styled(Rnd)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  background: #002140;
  border-radius: 5px;
  box-shadow: 1px 1px 5px black;
  z-index: 99999;
`;


export const Minimizable = (props) => {
  const [showing, setShowing] = useState(true);
  const [style, setStyle] = useState("inline-block");

  const Child = () => React.cloneElement(props.children);
  const Minimize = () => {
    setStyle("none");
    setShowing(false);
  };

  return (
    <>
      <>
        {React.cloneElement(props.children, {
          setShowing: Minimize,
          display: style,
        })}
      </>
      {!showing && (
        <AvatarRnd
          placement="bottom"
          closable="true"
          style={{ overflowY: "none" }}
          default={{
            x: props.x,
            y: props.y,
            //x: 20,
            //y: window.innerHeight / 1.25,
          }}
        >
          <Avatar
            size={64}
            onClick={() => {
              setShowing(true);
              setStyle("inline-block");
            }}
            style={{ cursor: "pointer" }}
          >
            {props.text}
          </Avatar>
        </AvatarRnd>
      )}
    </>
  );
};

export default Minimizable;
