import React, { useState, useEffect } from "react";
import { Api } from "../../api"; // Make sure this path matches your API module
import { Table, Button, Modal, Form, Input, InputNumber } from "antd";

const ConfiguracionVehicularAdmin = () => {
  const [configuraciones, setConfiguraciones] = useState([]);
  const [editingConfiguracion, setEditingConfiguracion] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [editForm] = Form.useForm();
  const [addForm] = Form.useForm();

  useEffect(() => {
    loadConfiguraciones();
  }, []);

  const loadConfiguraciones = async () => {
    let fetchedConfiguraciones =
      await Api.ConfiguracionesVehiculares.getAll();
      fetchedConfiguraciones = fetchedConfiguraciones.sort((a, b) => a.id - b.id);
    setConfiguraciones(fetchedConfiguraciones);
  };

  const showEditModal = (configuracion) => {
    setEditingConfiguracion(configuracion);
    editForm.setFieldsValue(configuracion);
    setIsModalVisible(true);
  };

  const showAddModal = () => {
    setIsAddModalVisible(true);
    addForm.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsAddModalVisible(false);
    setEditingConfiguracion(null);
  };

  const handleAdd = async () => {
    try {
      const values = await addForm.validateFields();
      await Api.ConfiguracionesVehiculares.create(values);
      loadConfiguraciones();
      setIsAddModalVisible(false);
    } catch (error) {
      console.error("Error adding configuration:", error);
    }
  };

  const handleEdit = async () => {
    try {
      const values = await editForm.validateFields();
      await Api.ConfiguracionesVehiculares.update(
        editingConfiguracion.id,
        values
      );
      loadConfiguraciones();
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error updating configuration:", error);
    }
  };

  const columns = [
    {
      title: 'Configuración Vehicular',
      dataIndex: 'configuracion_vehicular',
      key: 'configuracion_vehicular',
    },
    {
      title: 'Número de Ejes',
      dataIndex: 'numero_de_ejes',
      key: 'numero_de_ejes',
    },
    {
      title: 'Número de Llantas',
      dataIndex: 'numero_de_llantas',
      key: 'numero_de_llantas',
    },
    {
      title: 'Peso Bruto Vehicular (t) y Largo Total (metros)',
      children: [
        {
          title: 'ET',
          children: [
            {
              title: 'Peso',
              dataIndex: 'peso_bruto_vehicular_et',
              key: 'peso_bruto_vehicular_et',
            },
            {
              title: 'Largo',
              dataIndex: 'largo_total_metros_et',
              key: 'largo_total_metros_et',
            },
          ],
        },
        {
          title: 'A',
          children: [
            {
              title: 'Peso',
              dataIndex: 'peso_bruto_vehicular_a',
              key: 'peso_bruto_vehicular_a',
            },
            {
              title: 'Largo',
              dataIndex: 'largo_total_metros_a',
              key: 'largo_total_metros_a',
            },
          ],
        },
        {
          title: 'B',
          children: [
            {
              title: 'Peso',
              dataIndex: 'peso_bruto_vehicular_b',
              key: 'peso_bruto_vehicular_b',
            },
            {
              title: 'Largo',
              dataIndex: 'largo_total_metros_b',
              key: 'largo_total_metros_b',
            },
          ],
        },
        {
          title: 'C',
          children: [
            {
              title: 'Peso',
              dataIndex: 'peso_bruto_vehicular_c',
              key: 'peso_bruto_vehicular_c',
            },
            {
              title: 'Largo',
              dataIndex: 'largo_total_metros_c',
              key: 'largo_total_metros_c',
            },
          ],
        },
        {
          title: 'D',
          children: [
            {
              title: 'Peso',
              dataIndex: 'peso_bruto_vehicular_d',
              key: 'peso_bruto_vehicular_d',
            },
            {
              title: 'Largo',
              dataIndex: 'largo_total_metros_d',
              key: 'largo_total_metros_d',
            },
          ],
        },
      ],
    },
    // Actions column remains the same
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => showEditModal(record)}>Editar</Button>
      ),
    },
  ];
  

  return (
    <div style={{ padding: "20px", backgroundColor: "#002146", color: "#fff" }}>
      <Button type="primary" onClick={showAddModal}>
        Agregar Nueva Configuración
      </Button>
      <Table
        size="small"
        dataSource={configuraciones}
        columns={columns}
        rowKey="id"
        pagination={false}
      />

      <Modal
        title="Editar Configuración"
        visible={isModalVisible}
        onOk={handleEdit}
        onCancel={handleCancel}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            name="configuracion_vehicular"
            label="Configuración Vehicular"
            rules={[
              { required: true, message: "Ingrese la configuración vehicular" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="numero_de_ejes"
            label="Número de Ejes"
            rules={[{ required: true, message: "Ingrese el número de ejes" }]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="numero_de_llantas"
            label="Número de Llantas"
            rules={[
              { required: true, message: "Ingrese el número de llantas" },
            ]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
          {/* Peso Bruto Vehicular and Largo Total for each route type */}
          <Form.Item
            name="peso_bruto_vehicular_et"
            label="Peso Bruto Vehicular ET (t)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="largo_total_metros_et"
            label="Largo Total ET (metros)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="peso_bruto_vehicular_a"
            label="Peso Bruto Vehicular A (t)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="largo_total_metros_a" label="Largo Total A (metros)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="peso_bruto_vehicular_b"
            label="Peso Bruto Vehicular B (t)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="largo_total_metros_b" label="Largo Total B (metros)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="peso_bruto_vehicular_c"
            label="Peso Bruto Vehicular C (t)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="largo_total_metros_c" label="Largo Total C (metros)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="peso_bruto_vehicular_d"
            label="Peso Bruto Vehicular D (t)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="largo_total_metros_d" label="Largo Total D (metros)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Agregar Nueva Configuración"
        visible={isAddModalVisible}
        onOk={handleAdd}
        onCancel={handleCancel}
      >
        <Form form={addForm} layout="vertical">
          <Form.Item
            name="configuracion_vehicular"
            label="Configuración Vehicular"
            rules={[
              { required: true, message: "Ingrese la configuración vehicular" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="numero_de_ejes"
            label="Número de Ejes"
            rules={[{ required: true, message: "Ingrese el número de ejes" }]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="numero_de_llantas"
            label="Número de Llantas"
            rules={[
              { required: true, message: "Ingrese el número de llantas" },
            ]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
          {/* Peso Bruto Vehicular and Largo Total for each route type */}
          <Form.Item
            name="peso_bruto_vehicular_et"
            label="Peso Bruto Vehicular ET (t)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="largo_total_metros_et"
            label="Largo Total ET (metros)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="peso_bruto_vehicular_a"
            label="Peso Bruto Vehicular A (t)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="largo_total_metros_a" label="Largo Total A (metros)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="peso_bruto_vehicular_b"
            label="Peso Bruto Vehicular B (t)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="largo_total_metros_b" label="Largo Total B (metros)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="peso_bruto_vehicular_c"
            label="Peso Bruto Vehicular C (t)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="largo_total_metros_c" label="Largo Total C (metros)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="peso_bruto_vehicular_d"
            label="Peso Bruto Vehicular D (t)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="largo_total_metros_d" label="Largo Total D (metros)">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ConfiguracionVehicularAdmin;
