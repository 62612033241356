import axios from "axios";
import { v4 as uuidv4 } from "uuid";

class LangflowClient {
  constructor(baseURL, apiKey) {
    this.baseURL = baseURL;
    this.apiKey = apiKey;
    this.api = axios.create({ baseURL: this.baseURL });

    // Add request interceptor to include the access token
    this.api.interceptors.request.use(
      (config) => {
        if (this.apiKey) {
          config.headers["x-api-key"] = this.apiKey;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  async post(endpoint, body, headers = { "Content-Type": "application/json" }) {
    const url = `${this.baseURL}${endpoint}`;
    try {
      const response = await this.api.post(url, body, { headers });
      return response.data;
    } catch (error) {
      console.error("Request Error:", error);
      throw error;
    }
  }

  async initiateSession(flowId, inputValue, stream = false, tweaks = {}) {
    let sessionId = localStorage.getItem("secure_deviceid2");
    if (!sessionId) {
      sessionId = uuidv4();
      localStorage.setItem("secure_deviceid2", sessionId);
    }

    return sessionId;
    //const endpoint = `/api/v1/run/${flowId}?stream=${stream}`;
    /*return this.post(endpoint, {
      session_id: sessionId,
      input_value: inputValue,
      tweaks: tweaks,
    });*/
  }

  async initiateSession2(flowId, inputValue, stream = false, tweaks = {}) {
    let sessionId = localStorage.getItem("secure_deviceid2");
    if (!sessionId) {
      sessionId = uuidv4();
      localStorage.setItem("secure_deviceid2", sessionId);
    }

    const endpoint = `/api/v1/run/${flowId}?stream=${stream}`;
    return this.post(endpoint, {
      session_id: sessionId,
      input_value: inputValue,
      tweaks: tweaks,
    });
  }

  async fetchHistory(flowId, sessionId) {
    const endpoint = `${this.baseURL}/api/v1/monitor/messages?flow_id=2335adb9-d9e1-4bdf-a7aa-5a3b0d35d89b&session_id=${sessionId}`;
    console.log("Fetching history from:", endpoint);
    try {
      const response = await this.api.get(endpoint);
      return response.data;
    } catch (error) {
      console.error("Fetch History Error:", error);
      throw error;
    }
  }

  async deleteMessagesSession(sessionId) {
    const endpoint = `${this.baseURL}/api/v1/monitor/messages/session/${sessionId}`;
    try {
      await this.api.delete(endpoint);
    } catch (error) {
      console.error("Delete Messages Error:", error);
      throw error;
    }
  }

  handleStream(streamUrl, onUpdate, onClose, onError) {
    const eventSource = new EventSource(streamUrl);

    eventSource.onmessage = (event) => {
      console.log("onmessage:", event.data);
      const data = JSON.parse(event.data);
      onUpdate(data);
    };

    eventSource.onerror = (event) => {
      console.error("Stream Error:", event);
      onError(event);
      eventSource.close();
    };

    eventSource.addEventListener("close", () => {
      onClose("Stream closed");
      eventSource.close();
    });

    return eventSource;
  }

  async runFlow(
    flowIdOrName,
    inputValue,
    tweaks = {},
    stream = false,
    onUpdate,
    onClose,
    onError
  ) {
    try {
      const initResponse = await this.initiateSession2(
        flowIdOrName,
        inputValue,
        stream,
        tweaks
      );
      console.log("Init Response:", initResponse);
      if (
        stream &&
        initResponse &&
        initResponse.outputs &&
        initResponse.outputs[0].outputs[0].artifacts.stream_url
      ) {
        const streamUrl =
          initResponse.outputs[0].outputs[0].artifacts.stream_url;
        console.log(`Streaming from:${this.baseURL}${streamUrl}`);
        this.handleStream(
          `${this.baseURL}${streamUrl}`,
          onUpdate,
          onClose,
          onError
        );
      }
      return initResponse;
    } catch (error) {
      console.error("Error running flow:", error);
      throw new Error("Error initiating session");
    }
  }
}

export default LangflowClient;
