export const getPolylineColor = (tipo) => {
  const colorMap = {
    ET4: "#3C1980",
    ET: "#3C1980",
    ET2: "#3C1980",
    A: "#1C9F27",
    A4: "#1C9F27",
    A2: "#1C9F27",
    C: "#E4B508",
    D: "#D90012",
    B: "#0F7ED2",
    B4: "#0F7ED2",
    B2: "#0F7ED2",
    FEDERAL: "#DD388A",
  };

  return colorMap[tipo] || "#000000";
};

export const tipoBloqueo = (tipo) => {
  const bloqueoMap = {
    0: "no-especificado",
    1: "sin-afectacion",
    2: "parcial",
    3: "totalmente",
  };

  return bloqueoMap[tipo] || "";
};
