import React, { useState, useEffect, useRef } from "react";
import { List, Button, Tag, Modal, Typography, Spin } from "antd";
import { FaEraser } from "react-icons/fa";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { useSpring, animated } from "@react-spring/web";
import ChatInput from "./ChatInput";
import ChatFilters from "./ChatFilters";
import ReactMarkdown from "react-markdown";
import LangflowClient from "../utils/LangflowClient";
import "./ChatComponent.css";

const { Title } = Typography;

const ChatComponent = ({
  flow = {
    id: "smap2024",
    apiKey: "sk-u5W-aUvV18YVOx4y-ph_SDR_LLhBLtaqAL6rfNAdxys",
  },
  setOpen,
}) => {
  const [chatValue, setChatValue] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [lockChat, setLockChat] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const langflowClient = useRef(
    new LangflowClient("https://langflow.antp.org.mx", flow.apiKey)
  ).current;
  const messagesRef = useRef(null);
  const inputRef = useRef(null);
  const sessionId = useRef("");

  useEffect(() => {
    const initializeSession = async () => {
      try {
        const response = await langflowClient.initiateSession(flow.id, null);
        sessionId.current = response;
        await fetchChatHistory();
      } catch (error) {
        console.error("Error initializing session:", error);
      }
    };
    initializeSession();
  }, [flow.id]);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const addChatHistory = (message, isSend) => {
    setChatHistory((old) => [...old, { message, isSend }]);
  };

  const handleWsMessage = (data) => {
    console.log("Received message: ", JSON.stringify(data));

    console.log("Final Output:", data.message);
    let output = "";
    if (!data.message) {
      const flowOutputs = data.outputs[0];
      const firstComponentOutputs = flowOutputs.outputs[0];
      output = firstComponentOutputs.outputs.message.message;
      console.log("output", output.text);
    }

    setChatHistory((old) => [
      ...old,
      { message: data.message || output.text || "", isSend: false },
    ]);
  };

  const fetchChatHistory = async () => {
    try {
      const history = await langflowClient.fetchHistory(
        flow.id,
        sessionId.current
      );
      setChatHistory(
        history.map((msg) => ({
          message: msg.text,
          isSend: msg.sender === "User",
        }))
      );
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  const updateLastMessage = ({ str, end = false }) => {
    setChatHistory((old) => {
      let newChat = [...old];
      if (str) {
        newChat[newChat.length - 1].message += str;
      }
      return newChat;
    });
  };

  const sendAll = async (data) => {
    try {
      const response = await langflowClient.runFlow(
        flow.id,
        data.message,
        {},//filters,
        false,
        (data) => {
          console.log("Received:", data.chunk);
          updateLastMessage({ str: data.chunk });
        },
        (message) => {
          console.log("Stream Closed:", message);
        },
        (error) => {
          console.log("Stream Error:", error);
        }
      );
      if (response && response.outputs && response.outputs.length) {
        handleWsMessage(response);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLockChat(false);
    }
  };

  const formatFilters = (filters) => {
    return `Tipo de Análisis: ${
      filters.analisis_tipo || "Probabilidad o General"
    }, Fecha: ${filters.date || "N/A"}, Hora: ${
      filters.time || "N/A"
    }, Mercancía: ${filters.mercancia || "N/A"}, Tipo de Vehículo: ${
      filters.tipoVehiculo || "N/A"
    }`;
  };

  const sendMessage = () => {
    if (chatValue.trim() !== "") {
      setLockChat(true);
      const message = chatValue;
      setChatValue("");
      const filterString = formatFilters(filters);
      const fullMessage = `[FILTERS]${filterString}[/FILTERS] ${message}`;
      addChatHistory(fullMessage, true);
      sendAll({ message: fullMessage });
    } else {
      console.error("El mensaje no puede estar vacío.");
    }
  };

  const clearChat = async () => {
    try {
      await langflowClient.deleteMessagesSession(sessionId.current);
      setChatHistory([]);
    } catch (error) {
      console.error("Error clearing chat history:", error);
    } finally {
      setLockChat(false);
    }
  };

  const renderMessage = (chat) => {
    return chat?.message?.replace(/\[FILTERS\](.*?)\[\/FILTERS\]/, "").trim();
  };

  const renderMarkdownMessage = (chat) => {
    return <ReactMarkdown>{renderMessage(chat)}</ReactMarkdown>;
  };

  const animatedProps = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "95%",
        margin: "0 auto",
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <div style={{ position: "relative", marginBottom: "20px" }}>
        <Button
          onClick={() => clearChat()}
          icon={<FaEraser />}
          style={{
            position: "absolute",
            top: "20px",
            right: "0px",
            color: "gray",
            background: "transparent",
            border: "none",
            zIndex: 9999,
          }}
        />
      </div>
      <div
        ref={messagesRef}
        style={{
          width: "100%",
          height: "600px",
          background: "#ffffff",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          padding: "10px",
          overflowY: "auto",
        }}
      >
        {chatHistory.length > 0 ? (
          <List
            dataSource={chatHistory}
            renderItem={(chat, index) => (
              <animated.div style={animatedProps}>
                <List.Item
                  key={index}
                  className={
                    chat.isSend ? "chat-bubble-sent" : "chat-bubble-received"
                  }
                >
                  <List.Item.Meta
                    title={
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        {chat.isSend ? "Tú" : "S-MAP Asistente"}
                      </span>
                    }
                    description={
                      <span style={{ color: "black" }}>
                        {renderMarkdownMessage(chat)}
                      </span>
                    }
                  />
                </List.Item>
              </animated.div>
            )}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              textAlign: "center",
            }}
          >
            <span>
              👋{" "}
              <span style={{ color: "gray", fontSize: "18px" }}>S-MAP IA</span>
            </span>
            <br />
            <div
              style={{
                backgroundColor: "#f0f0f0",
                borderRadius: "8px",
                padding: "20px",
                border: "1px solid #d9d9d9",
              }}
            >
              <span style={{ color: "gray" }}>
                Inicia una conversación enviando un mensaje{" "}
                <span
                  style={{
                    display: "inline-block",
                    animation: "bounce 2s infinite",
                  }}
                >
                  <ChatBubbleOvalLeftEllipsisIcon
                    style={{ width: "24px", height: "24px" }}
                  />
                </span>{" "}
                para obtener información sobre robos carreteros.
              </span>
            </div>
          </div>
        )}
      </div>
      <div style={{ width: "100%", marginTop: "20px" }}>
        <Button type="primary" onClick={() => setFilterModalVisible(true)}>
          Filtros
        </Button>
        <Modal
          title="Set Filters"
          open={filterModalVisible}
          onCancel={() => setFilterModalVisible(false)}
          footer={null}
        >
          <ChatFilters setFilters={setFilters} />
        </Modal>
        <div style={{ marginBottom: "10px" }}>
          {Object.keys(filters).map((key) => (
            <Tag key={key} color="blue">
              {key}: {filters[key]}
            </Tag>
          ))}
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "20px" }}>
        <ChatInput
          chatValue={chatValue}
          lockChat={lockChat}
          sendMessage={sendMessage}
          setChatValue={setChatValue}
          inputRef={inputRef}
          disabled={lockChat}
        />
      </div>
      {lockChat && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin size="large" />
          <span style={{ marginLeft: "10px", color: "gray" }}>Pensando...</span>
        </div>
      )}
    </div>
  );
};

export default ChatComponent;
