import { React } from "react";
import Main from "./layouts/Main";
import { ConfigProvider, theme } from "antd";
import { LoadScriptNext } from "@react-google-maps/api";
import { FluentProvider, teamsDarkTheme } from "@fluentui/react-components";
import ErrorBoundary from "./ErrorBoundary";
import esES from "antd/lib/locale/es_ES";
// import "antd/dist/antd.dark.css";

import "antd/dist/reset.css";

import { MainContextProvider } from "./contexts/MainContext";
import { RutasContextProvider } from "./contexts/RutasContext";

import { useMatch } from "react-router-dom";

export const SmapComponent = (props) => {
  const match = useMatch("/ruta/:origen/:destino");
  console.log(match);
  const routeProps = {
    ...match?.params,
  };

  return (
    <ErrorBoundary>
      <LoadScriptNext
        googleMapsApiKey="AIzaSyDH-C4iqIro6M8aS9imAqS5k0Os6VIYbXo"
        libraries={["places", "visualization"]}
      >
        <FluentProvider theme={teamsDarkTheme}>
          <MainContextProvider {...props} {...routeProps}>
            <RutasContextProvider>
              <ConfigProvider
                locale={esES}
                theme={{
                  algorithm: theme.darkAlgorithm,
                }}
              >
                <Main />
              </ConfigProvider>
            </RutasContextProvider>
          </MainContextProvider>
        </FluentProvider>
      </LoadScriptNext>
    </ErrorBoundary>
  );
};

export default SmapComponent;
