import React from "react";
import { createRoot } from 'react-dom/client';

import 'winbox/dist/css/winbox.min.css'; // required
import 'winbox/dist/css/themes/modern.min.css'; // optional

import "./index.css";

import SmapComponent from "./SmapComponent";
import { BrowserRouter as Router } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
} else {
  // production code
  Sentry.init({
    dsn: "https://ed8f015223a648daa330d43cb157932a@o228472.ingest.sentry.io/5700180",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}


const origen = "Puebla, Mexico";
const destino = "Ciudad de México, CDMX, Mexico";
const camion = 27;
const smapProps = {
  //origen,
  //destino,
  //camion,
};

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
 
    <Router><SmapComponent {...smapProps} /></Router>

);
