import React, { useState, useEffect } from "react";
import Api from "../api";
const MainContext = React.createContext();

function setLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
}

function getLocalStorage(key, initialValue) {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
}

const MainContextProvider = ({ children, ...props }) => {
  const [token, setToken] = useState(() => getLocalStorage("token", undefined));

  const [permisos, setPermisos] = useState([]);
  const [ruta, setRuta] = useState({});

  const [dataPassed, setDataPassed] = useState([]);
  const [polygonPoints, setPolygonPoints] = useState([]);

  const [camion, setCamion] = useState(props.camion || 0);
  const [origen, setOrigen] = useState(props.origen);
  const [destino, setDestino] = useState(props.destino);
  const [escalas, setEscalas] = useState(props.escalas || []);
  const [cargandoRuta, setCargandoRuta] = useState(false);
  const [cargandoDirecciones, setCargandoDirecciones] = useState(false);
  const [cargandoPermisos, setCargandoPermisos] = useState(false);
  const [ferry, setFerry] = useState(false);
  const [mostrarBloqueos, setMostrarBloqueos] = useState(false);
  const [isClustering, setIsClustering] = useState(false);
  const [isHeatMapShown, setIsHeatMapShown] = useState(false);

  const [mapsProps, setMapsProps] = useState({
    center: {
      lat: 20.522593618909205,
      lng: -99.89245980249002,
    },
    zoom: 5,
  });
  const [mostrarMenu, setMostrarMenu] = useState(false);
  const [getMenu, setGetMenu] = useState([])

  const [intersecciones, setIntersecciones] = useState([]);

  const [direcciones, setDirecciones] = useState();

  const [res, setRes] = useState();
  const [escalasTipos, setEscalasTipos] = useState([]);
  const [escalasCamiones, setEscalasCamiones] = useState([]);

  const camiones = {
    0: "Sin vehiculo",
    1: "B2 - 2 Ejes, 6 Llantas",
    2: "B3 - 3 Ejes, 8 Llantas",
    38: "B3 - 3 Ejes, 10 Llantas",
    3: "B4 - 4 Ejes, 10 Llantas",
    4: "C2 - 2 Ejes, 6 Llantas",
    5: "C3 - 3 Ejes, 8 Llantas",
    39: "C3 - 3 Ejes, 10 Llantas",
    6: "C2-R2 - 4 Ejes, 14 Llantas",
    7: "C3-R2 - 5 Ejes, 18 Llantas",
    8: "C2-R3 - 5 Ejes, 18 Llantas",
    9: "C2-R3 - 6 Ejes, 22 Llantas",
    10: "T2-S1 - 3 Ejes, 10 Llantas",
    11: "T2-S2 - 4 Ejes, 14 Llantas",
    12: "T2-S3 - 5 Ejes, 18 Llantas",
    13: "T3-S1 - 4 Ejes, 14 Llantas",
    14: "T3-S2 - 5 Ejes, 18 Llantas",
    15: "T3-S3 - 6 Ejes, 22 Llantas",
    27: "T2-S1-R2 - 5 Ejes, 18 Llantas",
    28: "T2-S2-R2 - 6 Ejes, 22 Llantas",
    29: "T2-S1-R3 - 6 Ejes, 22 Llantas",
    30: "T3-S1-R2 - 6 Ejes, 22 Llantas",
    31: "T3-S1-R3 - 7 Ejes, 26 Llantas",
    32: "T3-S2-R2 - 7 Ejes, 26 Llantas",
    33: "T3-S2-R3 8 Ejes, 30 Llantas",
    34: "T3-S2-R4 - 9 Ejes, 34 Llantas",
    35: "T2-S2-S2 - 6 Ejes, 22 Llantas",
    36: "T3-S2-S2 - 7 Ejes, 26 Llantas",
    37: "T3-S3-S2 - 8 Ejes, 30 Llantas",
  };

  useEffect(() => {
    setLocalStorage("token", token);
    if (token) {
      Api.instance.setHeader("Authorization", `Bearer ${token}`);
    } else {
      Api.instance.setHeader("Authorization", null);
    }
  }, [token]);

  return (
    <MainContext.Provider
      value={{
        token,
        setToken,
        permisos,
        setPermisos,
        ruta,
        setRuta,
        camion,
        setCamion,
        setFerry,
        ferry,
        camiones,
        origen,
        destino,
        setOrigen,
        setDestino,
        intersecciones,
        setIntersecciones,
        cargandoRuta,
        setCargandoRuta,
        direcciones,
        setDirecciones,
        cargandoDirecciones,
        setCargandoDirecciones,
        cargandoPermisos,
        setCargandoPermisos,
        escalas,
        setEscalas,
        escalasTipos,
        setEscalasTipos,
        res,
        setRes,
        mostrarBloqueos,
        setMostrarBloqueos,
        escalasCamiones,
        setEscalasCamiones,
        mapsProps,
        setMapsProps,
        mostrarMenu,
        setMostrarMenu,
        getMenu,
        setGetMenu,
        dataPassed,
        setDataPassed,
        polygonPoints,
        setPolygonPoints,
        isClustering,
        setIsClustering,
        isHeatMapShown,
        setIsHeatMapShown,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export { MainContextProvider, MainContext };
