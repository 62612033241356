import React, { useState, useContext, useCallback, useEffect } from "react";
import {
  Form,
  Button,
  Select,
  DatePicker,
  TimePicker,
  Radio,
  message,
  Input,
  Typography,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { GoogleMap, Polyline } from "@react-google-maps/api";
import { RutasContext } from "../../contexts/RutasContext";
import { MainContext } from "../../contexts/MainContext"; // Assuming MainContext is where you handle API calls
import Api from '../../api'; // Adjust the path if necessary

dayjs.locale("es");

const { Option } = Select;
const { Title } = Typography;

const mapContainerStyle = {
  height: "200px",
  width: "100%",
};

const IAWizard = () => {
  const { rutas } = useContext(RutasContext);
  const { setProbabilidadRoboContext } = useContext(MainContext); // Assuming this is the method to set the context
  const [formData, setFormData] = useState({
    analysis_type: "probabilidad_de_robo",
    date: null,
    time: null,
    mercancia: "",
    tipoVehiculo: "",
  });
  const [tipoDeVehiculos, setTipoDeVehiculos] = useState([]);
  const [tipoDeMercancias, setTipoDeMercancias] = useState([]);
  const [map, setMap] = useState(null);

  const { analysis_type, date, time, mercancia, tipoVehiculo } = formData;
  const { origen, destino, tramos } = rutas;

  useEffect(() => {
    const fetchEnums = async () => {
      try {
        const [vehiculos, mercancias] = await Promise.all([
          Api.Enums.TipoDeVehiculos.getAll(),
          Api.Enums.TipoDeMercancias.getAll(),
        ]);

        setTipoDeVehiculos(vehiculos);
        setTipoDeMercancias(mercancias);
      } catch (error) {
        console.error("Error fetching enums:", error);
        message.error("Error al cargar los tipos de vehículo y mercancía.");
      }
    };

    fetchEnums();
  }, []);

  const onFormChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const nextStep = () => {
    if (!origen || !destino || !date || !time || !mercancia || !tipoVehiculo) {
      message.error("Por favor, complete todos los campos");
      return;
    }

    // Set the context for Probabilidad de Robo
    if (analysis_type === "probabilidad_de_robo") {
      setProbabilidadRoboContext({
        routeLine: tramos,
        date,
        time,
        mercancia,
        tipoVehiculo,
      });
    }

    message.success("¡Los datos están listos para ser procesados por la IA!");
    console.log("Datos Finales:", { ...formData, origen, destino });
  };

  const fitBounds = useCallback(() => {
    if (tramos && tramos.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();
      tramos.forEach((tramo) => {
        tramo.ruta.coordinates.forEach((coord) => {
          bounds.extend({ lat: coord[1], lng: coord[0] });
        });
      });
      map.fitBounds(bounds);
    }
  }, [tramos, map]);

  useEffect(() => {
    if (map) {
      fitBounds();
    }
  }, [fitBounds, map]);

  const onMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    if (tramos && tramos.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      tramos.forEach((tramo) => {
        tramo.ruta.coordinates.forEach((coord) => {
          bounds.extend({ lat: coord[1], lng: coord[0] });
        });
      });
      mapInstance.fitBounds(bounds);
    }
  }, [tramos]);

  return (
    <div style={{ padding: "20px" }}>
      <Form layout="vertical" onFinish={nextStep}>
        <Form.Item label="Selecciona el Tipo de Análisis">
          <Radio.Group
            value={analysis_type}
            onChange={(e) => onFormChange("analysis_type", e.target.value)}
          >
            <Radio value="probabilidad_de_robo">Probabilidad de Robo</Radio>
            <Radio value="estadistica">Estadística</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Origen">
          <Input value={origen || ""} readOnly />
        </Form.Item>
        <Form.Item label="Destino">
          <Input value={destino || ""} readOnly />
        </Form.Item>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          onLoad={onMapLoad}
        >
          {tramos &&
            tramos.length > 0 &&
            tramos.map((tramo, index) => (
              <Polyline
                key={index}
                path={tramo.ruta.coordinates.map((coord) => ({
                  lat: coord[1],
                  lng: coord[0],
                }))}
                options={{ strokeColor: "#FF0000" }}
              />
            ))}
        </GoogleMap>
        <Form.Item label="Fecha">
          <DatePicker
            format="YYYY-MM-DD"
            value={date ? dayjs(date, "YYYY-MM-DD") : null}
            onChange={(date, dateString) => onFormChange("date", dateString)}
          />
        </Form.Item>
        <Form.Item label="Hora">
          <TimePicker
            format="HH:mm"
            value={time ? dayjs(time, "HH:mm") : null}
            onChange={(time, timeString) => onFormChange("time", timeString)}
          />
        </Form.Item>
        <Form.Item label="Mercancía">
          <Select
            value={mercancia}
            onChange={(value) => onFormChange("mercancia", value)}
          >
            {tipoDeMercancias.map((tipo) => (
              <Option key={tipo.id} value={tipo.id}>
                {tipo.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Tipo de Vehículo">
          <Select
            value={tipoVehiculo}
            onChange={(value) => onFormChange("tipoVehiculo", value)}
          >
            {tipoDeVehiculos.map((tipo) => (
              <Option key={tipo.id} value={tipo.id}>
                {tipo.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Finalizar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default IAWizard;
