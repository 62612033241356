import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  Marker,
  Polyline,
  InfoWindow,
  MarkerClusterer,
  HeatmapLayer,
} from "@react-google-maps/api";
import { decode } from "../utils/PolylineDecoder";
import { checkCoordinates } from "../utils/CoordinatesChecker";
import parse from "html-react-parser";
import icon_color from "../files/icon_color.json";
import { Directions } from "../api";
import { RutasContext } from "../contexts/RutasContext";
import { MainContext } from "../contexts/MainContext";
import ReactPlayer from "react-player";
import { Button, message, Drawer, List, InputNumber } from "antd";
import * as turf from "@turf/turf";
import { SyncOutlined } from "@ant-design/icons";

import { getPolylineColor, tipoBloqueo } from "./utils";
import { json } from "react-router-dom";

const Gmaps = (props) => {
  const {
    permisos,
    setPermisos,
    setDirecciones,
    direcciones,
    origen,
    destino,
    cargandoRuta,
    setCargandoRuta,
    cargandoDirecciones,
    setCargandoDirecciones,
    escalas,
    setEscalas,
    res,
    setRes,
    ferry,
    mostrarBloqueos,
    mapsProps,
    setMapsProps,
    dataPassed,
    polygonPoints,
    isClustering,
    isHeatMapShown,
  } = useContext(MainContext);

  const [bloqueos, setBloqueos] = useState([]);
  const [loadingBloqueos, setLoadingBloqueos] = useState(false);
  const [mostrarBloqueosDrawer, setMostrarBloqueosDrawer] = useState(false);

  const [seconds, setSeconds] = useState(0);
  const [intervalo, setIntervalo] = useState(600);

  const [selectedPolyline, setSelectedPolyline] = useState(null);

  // Function to handle polyline click
  const onPolylineClick = useCallback((polylineData, index, event) => {
    console.log(polylineData);
    // Calculate the length of the polyline using turf.js
    const line = turf.lineString(polylineData.line.map((p) => [p[1], p[0]]));
    console.log(line);
    const length = turf.length(line, { units: "kilometers" });
    console.log(length);

    // Calculate the bounding box of the line
    const bbox = turf.bbox(line);

    console.log(bbox);

    // The bounding box is in the form [minLng, minLat, maxLng, maxLat]
    const topMiddlePointLng = (bbox[0] + bbox[2]) / 2; // Average of minLng and maxLng
    const topMiddlePointLat = bbox[3]; // maxLat

    // Determine the position for the InfoWindow (e.g., the midpoint of the polyline)
    const midpointIndex = Math.floor(polylineData.line.length / 2);
    const midpoint = polylineData.line[midpointIndex];
    console.log(midpoint);
    // Set the selected polyline state
    setSelectedPolyline({
      index,
      name: polylineData.properties.name,
      length: length.toFixed(2),
      properties: polylineData.properties,
      position: {
        lat: topMiddlePointLat,
        lng: topMiddlePointLng,
      },
    });
  }, []);

  const onPolylineUnmount = useCallback((index) => {
    setSelectedPolyline((currentSelected) => {
      // Clear selection if the unmounting polyline is currently selected
      return currentSelected?.index === index ? null : currentSelected;
    });
  }, []);

  const getBloqueos = async () => {
    setLoadingBloqueos(true);
    setSeconds(0);
    const url =
      "https://api.sheety.co/4148dd8fa269a9a81102b0266988999d/bloqueos/bloqueos";

    try {
      const response = await fetch(url);
      const json = await response.json();
      const bloqueos = json.bloqueos
        .filter((b) => b.activo === 1)
        .map((b) => ({
          tipo: b.tipo,
          position: { lat: b.latitud, lng: b.longitud },
          titulo: b.titulo,
          descripcion: b.descripcion,
          fecha: b.fecha,
          img: b.assetUrl,
          asset_tipo: b.assetTipo,
        }));

      setLoadingBloqueos(false);
      message.success("Bloqueos actualizados");
      setBloqueos(bloqueos);
    } catch (error) {
      message.error("No se pudieron cargar los bloqueos");
      setLoadingBloqueos(false);
      setSeconds(0);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (intervalo - seconds <= 0) mostrarBloqueos && getBloqueos();
  }, [mostrarBloqueos, seconds]);

  const [bloqueoWindow, setBloqueoWindow] = useState({
    tipo: 0,
    position: { lat: 0, lng: 0 },
    titulo: "",
    descripcion: "",
    fecha: "",
    img: "",
    visible: false,
  });

  const renderMarkers = (markers, clusterer = null) => {
    return markers.map((marker, index) => {
      let icon = null;
      if (images[marker.markerId]) {
        icon = {
          url: images[marker.markerId],
          scaledSize: new window.google.maps.Size(40, 40),
        };
      }

      return (
        <Marker
          key={index}
          clusterer={clusterer}
          onClick={() =>
            onMarkerClick(marker.n, marker.desc, {
              lat: marker.lat,
              lng: marker.lng,
            })
          }
          position={{ lat: marker.lat, lng: marker.lng }}
          name={marker.n}
          desc={marker.desc}
          icon={icon}
        />
      );
    });
  };

  const handleBloqueos = ({
    tipo,
    position,
    titulo,
    descripcion,
    fecha,
    img,
    asset_tipo,
  }) => {
    setBloqueoWindow({
      tipo,
      position,
      titulo,
      descripcion,
      fecha,
      img,
      visible: true,
      asset_tipo,
    });
  };

  let [infoW, setInfo] = useState({
    n: "",
    desc: "",
    position: { lat: 0, lng: 0 },
    visible: false,
  });
  let [selected, setSelected] = useState([]);
  let [routes, setRoutes] = useState([]);
  let [polypoints, setPolypoints] = useState([]);
  let [intPoints, setIntPoints] = useState([]);
  const [lineasTramos, setLineasTramos] = useState([]);
  const [lineasIntersecciones, setLineasIntersecciones] = useState([]);
  const [clickCoordinates, setClickCoordinates] = useState({});
  const { rutas, setRutas } = useContext(RutasContext);

  const [directionsRenderer, setDirectionsRenderer] = useState(null);

  function importAll(r) {
    return r.keys().reduce((images, item) => {
      images[item.replace("./", "").replace(".png", "")] = r(item);
      return images;
    }, {});
  }

  const images = importAll(require.context("../images", false, /\.(png)$/));

  const onMarkerClick = (n, desc, position) => {
    setInfo({
      n,
      desc,
      position,
      visible: true,
    });
  };

  //Para Markers
  useEffect(() => {
    if (dataPassed.length > 0) {
      setSelected([]);
      let s = [];
      dataPassed.forEach((obj) => {
        obj.markers.forEach((marker) => {
          s.push({
            lat: marker.lat,
            lng: marker.lng,
            markerId: obj.key,
            n: marker.name,
            desc: marker.desc,
          });
        });
      });
      console.log("polypoints", polypoints);
      if (polygonPoints.length > 0) {
        let nC = checkCoordinates(polypoints, s);
        let fNC = [];

        console.log("nC", nC);

        s.forEach((obj) => {
          nC.forEach((m) => {
            if (obj.lat === m.lat && obj.lng === m.lng) {
              fNC.push(obj);
            }
          });
        });
        setSelected(fNC);
      } else {
        setSelected(s);
      }
      //console.log(selected);
    } else {
      setSelected([]);
    }
  }, [dataPassed, polypoints]);

  //Para rutas del menu
  useEffect(() => {
    if (dataPassed.length > 0) {
      const r = dataPassed.flatMap((obj) =>
        obj.polyline.map((poly) => {
          const temp = poly.line.map((coords) => ({
            lat: coords[0],
            lng: coords[1],
          }));
          return [temp, obj.key, poly.name, poly];
        })
      );

      if (polygonPoints.length > 0) {
        const line1 = polypoints.map((p) => [p.lat, p.lng]);
        const intersectsWithRoute = r.filter(([x]) => {
          const line2 = x.map((li) => [li.lat, li.lng]);
          return (
            turf.lineIntersect(turf.lineString(line1), turf.lineString(line2))
              .features.length > 0
          );
        });
        setRoutes(intersectsWithRoute);
      } else {
        setRoutes(r);
      }
    } else {
      setRoutes([]);
    }
  }, [dataPassed, polypoints]);

  //Para Ruta Buscada con o sin escalas
  useEffect(() => {
    const fetchRoutes = async () => {
      if (polygonPoints.length > 0) {
        const decodedPoints = polygonPoints.map(decode).flat();
        const getCenter = (points) => (k) =>
          (points[0][k] + points[points.length - 1][k]) / 2;

        const lat = getCenter(decodedPoints)("lat");
        const lng = getCenter(decodedPoints)("lng");

        setMapsProps({
          center: {
            lat,
            lng,
          },
          zoom: 9,
        });

        setPolypoints(decodedPoints);

        const { escalas } = props;

        if (escalas) {
          const fetchInterPoints = async (inter) => {
            const res = await Directions.getPoint({ address: inter });

            return {
              lat: res.results[0].geometry.location.lat,
              lng: res.results[0].geometry.location.lng,
              n: inter,
              d: "Escala",
            };
          };

          const escalasIntermedias = await Promise.all(
            escalas.map(fetchInterPoints)
          );
          setIntPoints(escalasIntermedias);
        }

        const nC = checkCoordinates(decodedPoints, selected);

        const fNC = selected.filter((obj) =>
          nC.some((m) => obj.lat === m.lat && obj.lng === m.lng)
        );

        setSelected(fNC);
      } else {
        setPolypoints([]);
      }
    };
    fetchRoutes();
  }, [polygonPoints, props.escalas]);

  useEffect(() => {
    const mostrar = [1];

    const tt = permisos.map((permiso) => {
      const lineasTramos = permiso.tramos
        .filter((tramo) => tramo.show?.())
        .map((tramo, index) => {
          const puntos = tramo.puntos?.coordinates.map(([lng, lat]) => ({
            lat,
            lng,
          }));
          const tipo = tramo.tipo;
          return { puntos, tipo, index };
        });
      return lineasTramos;
    });

    const tt2 = permisos.map((permiso) => {
      console.log("ii", permiso.intersecciones);
      const lineasTramos = permiso.intersecciones
        .filter((tramo) => tramo.show?.())
        .map((tramo, index) => {
          const puntos = tramo.puntos?.coordinates.map(([lng, lat]) => ({
            lat,
            lng,
          }));
          const tipo = tramo.tipo;
          return { puntos, tipo, index };
        });
      return lineasTramos;
    });

    setLineasTramos(tt || []);
    setLineasIntersecciones(tt2 || []);
  }, [permisos]);

  const [map, setMap] = useState(null);

  const onLoad = useCallback(
    (map) => {
      mostrarBloqueos && getBloqueos();
      map.panTo(mapsProps.center);
      setMap(map);
    },
    [mostrarBloqueos]
  );

  const containerStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
  };
  return (
    <>
      {mostrarBloqueos && (
        <>
          <Button
            onClick={getBloqueos}
            size="small"
            type="primary"
            icon={<SyncOutlined />}
            loading={loadingBloqueos}
          >
            Actualizar Bloqueos ({intervalo - seconds})
          </Button>{" "}
          Intervalo (s):{" "}
          <InputNumber
            label
            size="small"
            min={120}
            max={600}
            defaultValue={intervalo}
            onChange={(v) => {
              setIntervalo(v);
            }}
          />{" "}
          <Button
            onClick={() => setMostrarBloqueosDrawer(true)}
            size="small"
            type="primary"
            ghost
            loading={loadingBloqueos}
          >
            Ver bloqueos
          </Button>
        </>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={mapsProps.zoom}
        onLoad={onLoad}
      >
        {routes.map((route, i) => {
          //console.log("r1", route);
          //console.log("r1", i);
          return (
            <Polyline
              path={route[0]}
              key={`${route[1]}_${i}`}
              onUnmount={() => onPolylineUnmount(i)}
              onClick={(event) => {
                if (route?.[3]?.properties.route) {
                  onPolylineClick(route?.[3], i, event);
                } else {
                  onMarkerClick(
                    route[2],
                    route?.[3]?.properties?.description || "",
                    {
                      lat: event.latLng.lat(),
                      lng: event.latLng.lng(),
                    }
                  );
                }
              }}
              options={{
                strokeColor:
                  selectedPolyline?.index === i
                    ? "#002140"
                    : icon_color[route[1]],
                strokeOpacity: 0.8,
                strokeWeight: selectedPolyline?.index === i ? 7 : 6,
              }}
            />
          );
        })}

        {isClustering ? (
          <MarkerClusterer>
            {(clusterer) => renderMarkers(selected, clusterer)}
          </MarkerClusterer>
        ) : (
          renderMarkers(selected)
        )}

        {isHeatMapShown && (
          <HeatmapLayer
            data={selected.map(
              (s) => new window.google.maps.LatLng(s.lat, s.lng)
            )}
          />
        )}

        {intPoints.map((marker, i) => {
          return (
            <Marker
              onClick={() =>
                onMarkerClick(marker.n, marker.desc, {
                  lat: marker.lat,
                  lng: marker.lng,
                })
              }
              position={{ lat: marker.lat, lng: marker.lng }}
              key={i}
              name={marker.n}
              desc={marker.d}
            />
          );
        })}

        {lineasTramos &&
          lineasTramos.length > 0 &&
          lineasTramos?.map((pun, puntosIndex) =>
            pun?.map((poly, index) => (
              <Polyline
                path={poly.puntos}
                key={`${index}_${pun.index}_tramos_permisos`}
                options={{
                  strokeColor: getPolylineColor(poly.tipo),
                  strokeOpacity: 1,
                  strokeWeight: 6,
                }}
              />
            ))
          )}

        {lineasIntersecciones &&
          lineasIntersecciones.length > 0 &&
          lineasIntersecciones?.map((pun, puntosIndex) =>
            pun?.map((poly, index) => (
              <Polyline
                path={poly.puntos}
                key={`${index}_${pun.index}_intersecciones_permisos`}
                options={{
                  strokeColor: getPolylineColor(poly.tipo),
                  strokeOpacity: 1,
                  strokeWeight: 6,
                }}
              />
            ))
          )}

        {!res && origen && destino && escalas && (
          <DirectionsService
            // required
            options={{
              destination: destino,
              origin: origen,
              waypoints: escalas,
              travelMode: "DRIVING",
              avoidFerries: !ferry,
            }}
            onLoad={() => {
              setCargandoRuta(true);
            }}
            // required
            callback={(directions, x) => {
              console.log(directions, x);
              setRes(directions);
              setCargandoDirecciones(true);
              //setDirecciones(directions);
              //setCargandoRuta(false);
            }}
          />
        )}

        {res && (
          <DirectionsRenderer
            directions={res}
            // required
            options={{
              draggable: true,
            }}
            onLoad={(renderer) => {
              console.log("drenderer", renderer);
              //setDirecciones(x.directions);
              setDirectionsRenderer(renderer);
            }}
            onUnmount={(x) => {
              console.log("changedz", x);
            }}
            onDirectionsChanged={() => {
              directionsRenderer &&
                console.log("changed", directionsRenderer.getDirections());
              directionsRenderer
                ? setDirecciones(directionsRenderer.getDirections())
                : setDirecciones(res);
              setCargandoRuta(false);
            }}
          />
        )}

        {/*BLOQUEOS*/}
        {mostrarBloqueos &&
          bloqueos.map((marker, i) => {
            return (
              <Marker
                onClick={() => handleBloqueos(marker)}
                position={marker.position}
                key={i}
                icon={{
                  url: images[tipoBloqueo(marker.tipo)].default,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
              />
            );
          })}

        {bloqueoWindow.visible && (
          <InfoWindow
            position={bloqueoWindow.position}
            onCloseClick={() =>
              setBloqueoWindow({
                tipo: 0,
                position: { lat: 0, lng: 0 },
                titulo: "",
                descripcion: "",
                fecha: "",
                img: "",
                visible: false,
                asset_tipo: null,
              })
            }
          >
            <div>
              <h2> {bloqueoWindow.titulo}</h2>
              <h3> {bloqueoWindow.fecha}</h3>
              {bloqueoWindow.descripcion}

              {bloqueoWindow.asset_tipo === 2 ? (
                <ReactPlayer
                  controls
                  muted
                  playing
                  loop
                  url={bloqueoWindow.img}
                />
              ) : (
                <img src={bloqueoWindow.img} width={300} height={"auto"} />
              )}
            </div>
          </InfoWindow>
        )}

        {infoW.visible && (
          <InfoWindow
            position={infoW.position}
            onCloseClick={() =>
              setInfo({
                n: "",
                desc: "",
                position: { lat: 0, lng: 0 },
                visible: false,
              })
            }
          >
            <div>
              <h2> {infoW.n}</h2>
              {parse(`${infoW.desc}`)}
            </div>
          </InfoWindow>
        )}

        {selectedPolyline && (
          <InfoWindow
            position={selectedPolyline.position}
            onCloseClick={() => setSelectedPolyline(null)}
          >
            <div style={{ backgroundColor: "#002140", padding: "10px" }}>
              <h2>{selectedPolyline.name}</h2>
              <p>Longitud aproximada: {selectedPolyline.length} km</p>
              {selectedPolyline.properties && (
                <div>
                  <p>Longitud oficial: {selectedPolyline.properties?.longitud} km</p>
                  <p>Tipo: {selectedPolyline.properties?.type}</p>
                  <p>No.: {selectedPolyline.properties?.no}</p>
                  <p>Ruta: {selectedPolyline.properties?.ruta}</p>
                </div>
              )}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      {mostrarBloqueos && mostrarBloqueosDrawer && (
        <Drawer
          title="Bloqueos"
          placement="right"
          open={mostrarBloqueosDrawer}
          closable
          onClose={() => setMostrarBloqueosDrawer(false)}
          getContainer={false}
          rootStyle={{ position: "absolute" }}
        >
          <List
            itemLayout="vertical"
            size="default"
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize: 3,
            }}
            dataSource={bloqueos}
            renderItem={(item, i) => (
              <List.Item key={`${item.titulo}_${i}`} actions={[]}>
                <List.Item.Meta
                  title={<a href={item.href}>{item.titulo}</a>}
                  description={item.descripcion}
                />
                {item.asset_tipo === 2 ? (
                  <ReactPlayer
                    controls
                    muted
                    height={100}
                    width={100}
                    playing
                    loop
                    url={item.img}
                  />
                ) : (
                  <img src={item.img} width={100} height={"auto"} />
                )}
              </List.Item>
            )}
          />
          <br></br>
          <Button
            onClick={getBloqueos}
            size="small"
            type="primary"
            icon={<SyncOutlined />}
            loading={loadingBloqueos}
          >
            Actualizar Bloqueos ({intervalo - seconds})
          </Button>
        </Drawer>
      )}
    </>
  );
};

export default Gmaps;
