import React, { useState, useEffect } from "react";
import Api from "../api";
const RutasContext = React.createContext();

const RutasContextProvider = ({ children }) => {
  const[rutas, setRutas] = useState()

  return (
    <RutasContext.Provider value={{ rutas, setRutas }}>
      {children}
    </RutasContext.Provider>
  );
}

export { RutasContextProvider, RutasContext };