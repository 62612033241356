import React, { useState, useEffect } from "react";
import { Api } from "../../api";
import {
  Form,
  Input,
  message,
  Divider,
  Modal,
  Transfer,
  Select,
  Button,
} from "antd";

const { Option } = Select;

const CapasAdmin = () => {
  const [capas, setCapas] = useState([]);

  const [form] = Form.useForm();

  const [unregisteredTables, setUnregisteredTables] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItemForRegistration, setSelectedItemForRegistration] =
    useState(null);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingCapa, setEditingCapa] = useState(null);

  useEffect(() => {
    loadCapas();
    loadUnregisteredTables();
  }, []);

  const loadCapas = async () => {
    setLoading(true);
    try {
      const fetchedCapas = await Api.Capas.listRegistered();
      setCapas(
        fetchedCapas.map((capa) => ({
          key: capa.token,
          title: capa.name,
          ...capa,
        }))
      );
      setTargetKeys(fetchedCapas.map((capa) => capa.token));
    } catch (error) {
      message.error("Error al cargar las capas registradas");
    } finally {
      setLoading(false);
    }
  };

  const loadUnregisteredTables = async () => {
    setLoading(true);
    try {
      const fetchedTables = await Api.Capas.listUnregistered();
      setUnregisteredTables(
        fetchedTables.map((table) => ({
          key: table,
          title: table,
          token: table,
        }))
      );
    } catch (error) {
      message.error("Error al cargar las tablas no registradas");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    if (direction === "right" && moveKeys.length > 0) {
      const itemToRegisterKey = moveKeys[0]; // For simplicity, take the first selected item
      const itemToRegister = unregisteredTables.find(
        (item) => item.key === itemToRegisterKey
      );
      setSelectedItemForRegistration(itemToRegister);
      setIsModalVisible(true);
      // Adjust the target keys based on the selection
      setTargetKeys((prevKeys) => prevKeys.concat(moveKeys));
    } else if (direction === "left" && moveKeys.length > 0) {
      // Confirm before unregistering
      Modal.confirm({
        title: "¿Estás seguro de que quieres desregistrar esta capa?",
        content: "Esta acción revertirá el registro de la capa seleccionada.",
        okText: "Desregistrar",
        cancelText: "Cancelar",
        onOk: () => handleUnregister(moveKeys),
      });
    }
  };

  const handleUnregister = async (moveKeys) => {
    // Logic to unregister the selected capa(s)
    // This example assumes unregistering one capa for simplicity
    console.log(moveKeys);
    try {
      await Api.Capas.unregisterByToken(moveKeys[0]);
      message.success("Capa desregistrada con éxito");
      loadCapas(); // Refresh registered capas
      loadUnregisteredTables(); // Refresh unregistered tables
    } catch (error) {
      message.error("Error al desregistrar la capa");
    }
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    // Enforce single selection by allowing only the last selected item to be marked as selected
    const newSelectedKeys =
      sourceSelectedKeys.length > 0
        ? [sourceSelectedKeys[sourceSelectedKeys.length - 1]]
        : targetSelectedKeys.length > 0
        ? [targetSelectedKeys[targetSelectedKeys.length - 1]]
        : [];
    setSelectedKeys(newSelectedKeys);
  };

  const handleRegistrationSubmit = async () => {
    const values = await form.validateFields();
    // Combina `selectedItemForRegistration` con los datos adicionales del formulario para registrar
    try {
      await Api.Capas.register({
        token: selectedItemForRegistration.key,
        ...values,
      });
      message.success("Capa registrada con éxito");
      setIsModalVisible(false);
      setSelectedItemForRegistration(null);
      form.resetFields();
      // Refresca tu lista de capas
      loadCapas();
      loadUnregisteredTables();
    } catch (error) {
      message.error("Error al registrar la capa");
    }
  };

  const openEditModal = (capa) => {
    form.setFieldsValue({
      name: capa.name,
      geometria_tipo: capa.geometria_tipo,
      tipo_espacial: capa.tipo_espacial,
      // Set other fields as needed
    });
    setEditingCapa(capa);
    setIsEditModalVisible(true);
  };

  const handleEditSubmit = async () => {
    const values = await form.validateFields();
    try {
      await Api.Capas.updateByToken(editingCapa.key, values);
      message.success("Capa actualizada con éxito");
      setIsEditModalVisible(false);
      setEditingCapa(null);
      form.resetFields();
      // Refresh data
      loadCapas();
    } catch (error) {
      message.error("Error al actualizar la capa");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2>Administración de Capas</h2>

      <Divider />

      <Transfer
        dataSource={[...capas, ...unregisteredTables]}
        titles={["Capas Sin registrar", "Capas Registradas"]}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={handleChange}
        onSelectChange={handleSelectChange}
        render={(item) => (
          <>
            {`${item.title} (${item.token})`}
            {targetKeys.includes(item.key) && (
              <Button
                size="small"
                style={{ marginLeft: 8 }}
                onClick={() => openEditModal(item)}
              >
                Editar
              </Button>
            )}
          </>
        )}
        disabled={loading}
        oneWay
        listStyle={{ width: "100%", height: 400 }}
        style={{ marginBottom: 20 }}
      />
      <Modal
        title={editingCapa ? "Editar Capa" : "Registrar Capa"}
        open={isModalVisible || isEditModalVisible}
        onOk={() =>
          editingCapa ? handleEditSubmit() : handleRegistrationSubmit()
        }
        onCancel={() => {
          setIsModalVisible(false);
          setIsEditModalVisible(false);
          setEditingCapa(null);
        }}
        okText={editingCapa ? "Guardar Cambios" : "Registrar"}
        cancelText="Cancelar"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Nombre"
            rules={[
              {
                required: true,
                message: "¡Por favor ingrese el nombre de la capa!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="geometria_tipo"
            label="Tipo de Geometría"
            rules={[
              {
                required: true,
                message: "¡Por favor seleccione el tipo de geometría!",
              },
            ]}
          >
            <Select placeholder="Seleccione el tipo de geometría">
              <Option value="Geometría">Geometría</Option>
              <Option value="Geografía">Geografía</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="tipo_espacial"
            label="Tipo Espacial"
            rules={[
              {
                required: true,
                message: "¡Por favor seleccione el tipo espacial!",
              },
            ]}
          >
            <Select placeholder="Seleccione el tipo espacial">
              <Option value="LineStringZ">LineStringZ (líneas o tramos)</Option>
              <Option value="PointZ">PointZ (puntos)</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CapasAdmin;
