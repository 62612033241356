import React, { useState, useEffect, useRef } from "react";
import { Api } from "../../api";
import {
  Select,
  Form,
  Button,
  Input,
  Upload,
  message,
  Divider,
  Row,
  Col,
  Modal,
  Checkbox,
  Alert,
  Skeleton,
} from "antd";
import JSZip from "jszip";
import * as toGeoJSON from "@tmcw/togeojson";
import { UploadOutlined } from "@ant-design/icons";
import { GoogleMap, Polyline } from "@react-google-maps/api";
import WKT from "wkt";
import * as turf from "@turf/turf";

const { Option } = Select;

const calculateCenterOfLineString = (lineString) => {
  let latSum = 0,
    lngSum = 0,
    count = 0;
  lineString.coordinates.forEach(([lng, lat]) => {
    latSum += lat;
    lngSum += lng;
    count++;
  });
  return { lat: latSum / count, lng: lngSum / count };
};

const TramosCarreterosAdmin = () => {
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);

  const [geoJsonFeatures, setGeoJsonFeatures] = useState([]);
  const [selectedGeoJsonFeature, setSelectedGeoJsonFeature] = useState(null);

  const [updateRouteName, setUpdateRouteName] = useState(true);
  const [newRouteName, setNewRouteName] = useState("");

  const [showInsertForm, setShowInsertForm] = useState(false);
  const [selectionEnabled, setSelectionEnabled] = useState(false);
  const [elementsCount, setElementsCount] = useState(0);
  const selectRef = useRef();

  useEffect(() => {
    loadRoutes();
  }, []);

  const loadRoutes = async () => {
    try {
      const fetchedRoutes = await Api.Rutas.listSimple();
      setRoutes(fetchedRoutes);
    } catch (error) {
      message.error("Error al cargar los tramos");
    }
  };

  const convertLineStringToPath = (lineString) => {
    console.log(lineString);
    return lineString.coordinates.map((coord) => ({
      lat: parseFloat(coord[1]),
      lng: parseFloat(coord[0]),
    }));
  };

  const handleRouteSelect = async (routeId) => {
    try {
      const detailedRoute = await Api.Rutas.getById(routeId);
      if (!detailedRoute) {
        throw new Error("No se encontró información detallada del tramo.");
      }

      // Update the form with the selected route's details
      form.setFieldsValue({
        route: detailedRoute.route,
        type: detailedRoute.type,
        no: detailedRoute.no,
        longitud: detailedRoute.longitud,
        ruta: detailedRoute.ruta,
      });

      // If path_text is available, process it
      if (detailedRoute.path_text) {
        let pathGeoJson = WKT.parse(detailedRoute.path_text);
        const center = turf.center(pathGeoJson); // Turf.js to calculate center

        setSelectedRoute({
          ...detailedRoute,
          path: pathGeoJson.coordinates,
          center: center.geometry.coordinates,
        });
      } else {
        // Handle the case where path_text is null or not available
        setSelectedRoute({
          ...detailedRoute,
          path: [], // Set an empty path
          center: { lat: 0, lng: 0 }, // Default or null coordinates
        });
      }
    } catch (error) {
      message.error("Error al cargar detalles del tramo: " + error.message);
      setSelectedRoute(null); // Reset selected route on error
      form.resetFields(); // Reset form fields
    }
  };

  const clearSelection = () => {
    setSelectedRoute(null);
    form.resetFields();
    setIsFormChanged(false);
  };

  const onFormChange = () => {
    setIsFormChanged(true);
  };

  const onGeoJsonFeatureSelect = (featureId) => {
    const selectedFeature = geoJsonFeatures.find(
      (feature) => feature.id === featureId
    );

    if (selectedFeature) {
      setNewRouteName(selectedFeature.name); // Set the new route name
      setSelectedGeoJsonFeature(selectedFeature);
    } else {
      setNewRouteName(""); // Reset the new route name if no feature is selected
    }
  };

  const handleEditRoute = async (values) => {
    try {
      await Api.Rutas.update(selectedRoute.id, values);
      message.success("Tramo actualizado con éxito");
      loadRoutes();
      setIsFormChanged(false);
    } catch (error) {
      message.error("Error al actualizar el tramo");
    }
  };

  const handleFileUpload = (file) => {
    setSelectionEnabled(false);
    setSelectedGeoJsonFeature(null);
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        if (
          file.type === "application/vnd.google-earth.kmz" ||
          file.name.endsWith(".kmz")
        ) {
          // Handling KMZ files
          const zip = new JSZip();
          const content = await zip.loadAsync(e.target.result);
          const kmlFilename = Object.keys(content.files).find((filename) =>
            filename.endsWith(".kml")
          );
          if (!kmlFilename) {
            throw new Error(
              "No se encontró un archivo KML dentro del archivo KMZ."
            );
          }
          const kmlContent = await content.files[kmlFilename].async("text");
          processKML(kmlContent);
        } else if (
          file.type === "application/vnd.google-earth.kml+xml" ||
          file.name.endsWith(".kml")
        ) {
          // Handling KML files
          processKML(e.target.result);
        } else {
          throw new Error("Tipo de archivo no soportado");
        }
      } catch (error) {
        message.error("Error al procesar el archivo: " + error.message);
      }
    };

    if (
      file.type === "application/vnd.google-earth.kmz" ||
      file.name.endsWith(".kmz")
    ) {
      reader.readAsArrayBuffer(file); // For KMZ files
    } else if (
      file.type === "application/vnd.google-earth.kml+xml" ||
      file.name.endsWith(".kml")
    ) {
      reader.readAsText(file); // For KML files
    }

    return false; // To prevent the default upload behavior
  };

  const processKML = async (kmlText) => {
    const parser = new DOMParser();
    const xml = parser.parseFromString(kmlText, "text/xml");
    // Check for NetworkLink and fetch linked content if present
    const networkLinkHref = xml.querySelector("NetworkLink > Link > href");
    if (networkLinkHref) {
      const url = networkLinkHref.textContent;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Error al obtener el contenido vinculado del KML");
        }
        const linkedKmlText = await response.text();
        const linkedXml = new DOMParser().parseFromString(
          linkedKmlText,
          "text/xml"
        );
        convertAndSetGeoJson(linkedXml);
      } catch (error) {
        message.error(
          "Error al procesar el contenido vinculado: " + error.message
        );
      }
    } else {
      // Directly convert and process the KML file
      convertAndSetGeoJson(xml);
    }
  };

  const convertAndSetGeoJson = (xml) => {
    const convertedData = toGeoJSON.kml(xml);
    setGeoJsonData(convertedData);
    const lineStringFeatures = convertedData.features
      .filter((feature) => feature.geometry.type === "LineString")
      .map((feature, index) => ({
        id: index,
        name: feature.properties.name || `Tramo ${index + 1}`,
        geometry: feature.geometry,
      }));
    setGeoJsonFeatures(lineStringFeatures);
    setElementsCount(lineStringFeatures.length); // Update elements count
    setSelectionEnabled(true);
    message.success(
      `El archivo KML/KMZ ha sido procesado exitosamente. Se encontraron ${lineStringFeatures.length} elementos.`
    );
  };

  const confirmReplaceRoute = () => {
    Modal.confirm({
      title: "¿Estás seguro de que quieres reemplazar este tramo?",
      content: "Esta acción no se puede deshacer.",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: () => {
        handleReplaceRoute();
      },
    });
  };

  const handleReplaceRoute = async () => {
    if (!selectedRoute || !selectedGeoJsonFeature) {
      message.error("Por favor, seleccione un tramo actual y un tramo nuevo");
      return;
    }

    try {
      const line = turf.lineString(selectedGeoJsonFeature.geometry.coordinates);
      const wktPath = WKT.stringify(line);
      const updateData = { path: wktPath };

      if (updateRouteName && newRouteName.trim() !== "") {
        updateData.route = newRouteName.trim();
      }

      await Api.Rutas.update(selectedRoute.id, updateData);

      message.success("Tramo reemplazado con éxito");
      loadRoutes();
      clearSelection(); // Optionally clear the selection
    } catch (error) {
      message.error("Error al reemplazar el tramo: " + error.message);
    }
  };

  const handleSearch = (value) => {
    // Optional: Implement logic to filter or search routes based on the input value
    // This might involve making an API call to fetch filtered routes
  };

  // Function to confirm deletion
  const confirmDeleteRoute = (routeId) => {
    Modal.confirm({
      title: "¿Estás seguro de que quieres eliminar este tramo?",
      content: "Esta acción no se puede deshacer.",
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: () => {
        handleDeleteRoute(routeId);
      },
    });
  };

  // Function to handle route deletion
  const handleDeleteRoute = async (routeId) => {
    try {
      await Api.Rutas.delete(routeId);
      message.success("Tramo eliminado con éxito");
      loadRoutes();
      clearSelection();
    } catch (error) {
      message.error("Error al eliminar el tramo");
    }
  };

  // Function to handle route insertion
  const handleInsertRoute = async (values) => {
    try {
      await Api.Rutas.insert(values);
      message.success("Tramo agregado con éxito");
      loadRoutes();
      clearSelection();
      setShowInsertForm(false); // Hide the form after successful insertion
    } catch (error) {
      message.error("Error al agregar el tramo");
    }
  };

  return (
    <div style={{ padding: "20px", backgroundColor: "#002146", color: "#fff" }}>
      <h2 style={{ color: "#fff" }}>Administración de Tramos Carreteros</h2>

      <Button
        type="primary"
        onClick={() => setShowInsertForm(true)}
        style={{ marginBottom: "20px" }}
      >
        Agregar Nuevo Tramo
      </Button>

      {showInsertForm && (
        <Form
          onFinish={handleInsertRoute}
          layout="vertical"
          style={{ marginBottom: "20px" }}
        >
          <Form.Item
            label="Nombre del Tramo"
            name="route"
            rules={[
              {
                required: true,
                message: "Por favor ingresa el nombre del tramo",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Tipo" name="type">
            <Input />
          </Form.Item>
          <Form.Item label="Número" name="no">
            <Input />
          </Form.Item>
          <Form.Item label="Longitud" name="longitud">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Ruta" name="ruta">
            <Input />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Agregar Tramo
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => setShowInsertForm(false)}
          >
            Cancelar
          </Button>
        </Form>
      )}

      <Divider style={{ borderColor: "#fff" }} />

      <Select
        ref={selectRef}
        showSearch
        allowClear
        value={selectedRoute?.id}
        placeholder="Selecciona un tramo"
        style={{ width: "100%", marginBottom: "20px" }}
        onChange={handleRouteSelect}
        onSearch={handleSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {routes.map((route) => (
          <Option key={route.id} value={route.id}>
            {route.route}
          </Option>
        ))}
      </Select>

      <Button
        disabled={!selectedRoute}
        onClick={clearSelection}
        style={{ marginBottom: "20px" }}
      >
        Limpiar Selección
      </Button>

      <Button
        onClick={() => confirmDeleteRoute(selectedRoute.id)}
        type="dashed"
        danger
        disabled={!selectedRoute}
        style={{ marginLeft: "10px" }}
      >
        Eliminar Tramo
      </Button>

      {selectedRoute && (
        <Form
          form={form}
          onFinish={handleEditRoute}
          layout="vertical"
          onValuesChange={onFormChange}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item name="route" label="Nombre del Tramo">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="type" label="Tipo">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item name="no" label="Número">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="longitud" label="Longitud">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="ruta" label="Tramo">
            <Input />
          </Form.Item>
          {isFormChanged && (
            <Button type="primary" htmlType="submit">
              Actualizar Tramo
            </Button>
          )}
        </Form>
      )}

      <Divider style={{ borderColor: "#fff" }}>Cargar Datos de Tramo</Divider>

      <Upload
        accept=".kml,.kmz"
        maxCount={1}
        listType="picture"
        multiple={false}
        beforeUpload={handleFileUpload}
        style={{ marginBottom: "20px" }}
      >
        <Button icon={<UploadOutlined />}>Cargar Archivo KMZ/KML</Button>
      </Upload>

      {elementsCount > 0 && (
        <p>{`Elementos encontrados: ${elementsCount}`}</p> // Display the count of found elements
      )}

      {selectionEnabled && geoJsonData && elementsCount > 0 && (
        <>
          <h3>Seleccionar Nuevo Tramo:</h3>

          {!selectionEnabled ? (
            <Alert
              message="Sube un archivo KML/KMZ para continuar."
              type="warning"
            />
          ) : (
            <Alert
              message="Selecciona un Tramo del desplegable para proceder."
              type="warning"
            />
          )}
          <Select
            className={selectionEnabled ? "highlight-selection" : ""}
            showSearch
            disabled={!selectionEnabled}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Elige una característica"
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={onGeoJsonFeatureSelect}
          >
            {geoJsonFeatures.map((feature) => (
              <Option key={feature.id} value={feature.id}>
                {feature.name}
              </Option>
            ))}
          </Select>

          {/*selectedGeoJsonFeature && (
            <div>
              <h4>Geometría:</h4>
              <Input.TextArea
                rows={4}
                value={JSON.stringify(selectedGeoJsonFeature.geometry, null, 2)}
                readOnly
              />
            </div>
          )*/}
        </>
      )}

      <Row gutter={16}>
        <Col span={12}>
          <div>
            <h3>Mapa de Tramo Actual:</h3>

            {selectedRoute ? (
              <div>
                <h5>{selectedRoute.route}</h5>
                <GoogleMap
                  mapContainerStyle={{ height: "150px", width: "100%" }}
                  center={{
                    lat: selectedRoute.center[1],
                    lng: selectedRoute.center[0],
                  }}
                  zoom={9}
                >
                  <Polyline
                    path={selectedRoute.path.map((coord) => ({
                      lat: coord[1],
                      lng: coord[0],
                    }))}
                  />
                </GoogleMap>
              </div>
            ) : (
              <Skeleton active />
            )}
          </div>
        </Col>
        <Col span={12}>
          <div>
            <h3>Mapa de Nuevo Tramo:</h3>
            {selectedGeoJsonFeature ? (
              <div>
                <Checkbox
                  checked={updateRouteName}
                  onChange={(e) => setUpdateRouteName(e.target.checked)}
                >
                  Actualizar Nombre del Tramo
                </Checkbox>

                {updateRouteName && (
                  <Input
                    style={{ marginTop: "10px" }}
                    placeholder="Nuevo Nombre del Tramo"
                    value={newRouteName}
                    onChange={(e) => setNewRouteName(e.target.value)}
                  />
                )}
                <GoogleMap
                  mapContainerStyle={{ height: "150px", width: "100%" }}
                  center={calculateCenterOfLineString(
                    selectedGeoJsonFeature.geometry
                  )}
                  zoom={9} // Adjust as needed
                >
                  <Polyline
                    path={convertLineStringToPath(
                      selectedGeoJsonFeature.geometry
                    )}
                  />
                </GoogleMap>
              </div>
            ) : (
              <Skeleton active />
            )}
          </div>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col span={24} style={{ textAlign: "center" }}>
          <Button disabled={!selectedGeoJsonFeature || !selectedRoute} onClick={() => confirmReplaceRoute()} type="dashed">
            Reemplazar Tramo
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TramosCarreterosAdmin;
