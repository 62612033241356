import {  SendOutlined } from "@ant-design/icons";
import { LockClosedIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { Input, Button } from "antd";
import { useEffect, useRef } from "react";

export default function ChatInput({
  lockChat,
  chatValue,
  sendMessage,
  setChatValue,
  inputRef,
}) {


  return (
    <div style={{ position: "relative" }}>
      <Input.TextArea
        onKeyDown={(event) => {
          if (event.key === "Enter" && !lockChat && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
          }
        }}
        ref={inputRef}
        disabled={lockChat}
        value={lockChat ? "Pensando..." : chatValue}
        onChange={(e) => {
          setChatValue(e.target.value);
        }}
        placeholder="Enviar un mensaje..."
      />
      <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
        <Button
          type="text"
          icon={
            lockChat ? (
              <LockClosedIcon />
            ) : (
              <SendOutlined style={{ fontSize: "20px" }} />
            )
          }
          disabled={lockChat}
          onClick={() => sendMessage()}
        />
      </div>
    </div>
  );
}
