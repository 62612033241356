import React, { useState, useContext, useEffect } from "react";
import { Layout, Menu, Switch, Badge, Button } from "antd";
import * as icons from "react-icons";
import { FaTv } from "react-icons/fa";
import { ImBlocked } from "react-icons/im";
import { GiEarthAmerica } from "react-icons/gi";
import { FacebookFilled, TwitterOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { MainContext } from "../contexts/MainContext";
import { Api } from "../api";
import { useMatch } from "react-router-dom";

import ColorPicker from "./ColorPicker";

const first = (v) => v[Object.keys(v)[0]];
const { SubMenu } = Menu;
const { Sider } = Layout;
const StyledSubMenu = styled(SubMenu)`
  white-space: normal;
  height: auto;
`;
const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const getIcon = (icon) => {
  if (icon == "FaTv")
    return <FaTv className={("anticon", "ant-menu-item-icon")} />;
  return icons[icon];
};

export const Sidebar = (props) => {
  const isBloqueosRoute = useMatch({
    path: "/apps/bloqueos_carreteros",
    strict: true,
    sensitive: true,
  });

  const { mostrarBloqueos, setMostrarBloqueos } = useContext(MainContext);
  const [collapsed, setCollapsed] = useState(false);
  const { handleToggle, clearFilters, showDrawer, onClose } = props;
  const toggleCollapsed = () => setCollapsed(!collapsed);
  const { getMenu, setGetMenu } = useContext(MainContext);
  const [subMenuCount, setSubMenuCount] = useState({});

  const [switchStates, setSwitchStates] = useState({});

  const getMenus = async () => {
    const responseMenus = await Api.Menu.getMenus();
    const menusMap = responseMenus.map((menu) => {
      return {
        name: menu.name,
        icon: getIcon(menu.icon),
        items: menu.capas,
      };
    });
    setGetMenu(menusMap);
  };
  useEffect(() => {
    isBloqueosRoute && setMostrarBloqueos(true);
    isBloqueosRoute && toggleCollapsed();
    getMenus();
  }, []);

  const handleClearFilters = () => {
    clearFilters();
    setSwitchStates({});
    setSubMenuCount({});
  };

  const anySwitchChecked = () => {
    return Object.values(switchStates).some((checked) => checked);
  };

  return (
    <Sider
      width={250}
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
    >
      <Button danger onClick={handleClearFilters} disabled={!anySwitchChecked()}>
        Borrar Filtros
      </Button>
      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
        {isBloqueosRoute && (
          <StyledSubMenu
            key="bloqueos"
            icon={<ImBlocked className={("anticon", "ant-menu-item-icon")} />}
            title="Bloqueos Carreteros Marzo 2021"
            style={{ backgroundColor: "#de7e00" }}
          >
            <Menu.Item key="2">
              Bloqueos Marzo 2021
              <Switch
                defaultChecked={false}
                onChange={(checked) => setMostrarBloqueos(checked)}
              />
            </Menu.Item>
          </StyledSubMenu>
        )}

        <StyledSubMenu
          key="sub0"
          icon={<FaTv className={("anticon", "ant-menu-item-icon")} />}
          title="Sistema"
        >
          <Menu.Item key="1" onClick={showDrawer}>
            Permisos
          </Menu.Item>
          <Menu.Item key="2">Patrocinadores</Menu.Item>
        </StyledSubMenu>
        {/*Menus Y SubMenus*/}

        {getMenu.map((subMenu, i) => (
          <StyledSubMenu
            key={`${subMenu.name}_${i}`}
            icon={subMenu.icon}
            title={
              <span>
                {subMenu.name}
                <Badge count={subMenuCount[subMenu.name] || 0} />
              </span>
            }
          >
            {subMenu.items.map((subItem) => (
              <StyledMenuItem key={subItem.token}>
                {subItem.name}
                <Switch
                  checked={switchStates[subItem.token] || false}
                  defaultChecked={false}
                  onChange={(checked) => {
                    handleToggle(checked, subItem.token);
                    setSwitchStates({ ...switchStates, [subItem.token]: checked });
                    const count = subMenuCount[subMenu.name] || 0;
                    setSubMenuCount({
                      ...subMenuCount,
                      [subMenu.name]: checked ? count + 1 : count - 1,
                    });
                  }}
                />
                {/*<ColorPicker />*/}
              </StyledMenuItem>
            ))}
          </StyledSubMenu>
        ))}

        <StyledSubMenu
          key="52"
          icon={
            <FacebookFilled className={("anticon", "ant-menu-item-icon")} />
          }
          onTitleClick={() =>
            window.open("https://www.facebook.com/smapmx", "_blank")
          }
          title="Facebook"
          className={"fb"}
        />

        <StyledSubMenu
          key="53"
          icon={
            <TwitterOutlined className={("anticon", "ant-menu-item-icon")} />
          }
          title="Twitter"
          onTitleClick={() =>
            window.open("https://www.twitter.com/smapmx", "_blank")
          }
          className={"twt"}
        />
      </Menu>
    </Sider>
  );
};

export default Sidebar;
