import { Toolbar, ToolbarToggleButton } from "@fluentui/react-components";
import React, { useContext } from "react";
import { MainContext } from "../contexts/MainContext";
import {
  FontIncrease24Regular,
  FontDecrease24Regular,
  TextFont24Regular,
  NumberCircle124Regular,
} from "@fluentui/react-icons";

export const ToolBar = (props) => {
  const { isClustering, setIsClustering, isHeatMapShown, setIsHeatMapShown } = useContext(MainContext);

  return (
    <Toolbar
      {...props}
      aria-label="Small"
      size="small"
      style={{
        border: "2px solid black",
        borderRadius: "8px",
      }}
    >
      <ToolbarToggleButton
        aria-label="Cluster"
        appearance="primary"
        checked={isClustering}
        onClick={() => setIsClustering(!isClustering)}
        icon={<NumberCircle124Regular />}
      >
        Ver cómo puntos
      </ToolbarToggleButton>
      <ToolbarToggleButton
        aria-label="Cluster"
        appearance="primary"
        checked={isHeatMapShown}
        onClick={() => setIsHeatMapShown(!isHeatMapShown)}
        icon={<NumberCircle124Regular />}
      >
        Ver cómo mapa de calor
      </ToolbarToggleButton>
    </Toolbar>
  );
};
