import React, { useState, useEffect } from "react";
import { Form, Select, DatePicker, TimePicker, Radio, message, Button } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/es";
import Api from "../../api"; // Adjust the path if necessary

dayjs.locale("es");

const { Option } = Select;

const ChatFilters = ({ setFilters }) => {
  const [formData, setFormData] = useState({
    analisis_tipo: "Probabilidad de Robo",
    date: null,
    time: null,
    mercancia: "",
    tipoVehiculo: "",
  });
  const [tipoDeVehiculos, setTipoDeVehiculos] = useState([]);
  const [tipoDeMercancias, setTipoDeMercancias] = useState([]);

  const { analisis_tipo, date, time, mercancia, tipoVehiculo } = formData;

  useEffect(() => {
    const fetchEnums = async () => {
      try {
        const [vehiculos, mercancias] = await Promise.all([
          Api.Enums.TipoDeVehiculos.getAll(),
          Api.Enums.TipoDeMercancias.getAll(),
        ]);

        setTipoDeVehiculos(vehiculos);
        setTipoDeMercancias(mercancias);
      } catch (error) {
        console.error("Error fetching enums:", error);
        message.error("Error al cargar los tipos de vehículo y mercancía.");
      }
    };

    fetchEnums();
  }, []);

  const onFormChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const applyFilters = () => {
    setFilters(formData);
    message.success("¡Los filtros se han aplicado!");
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Tipo de Análisis">
        <Radio.Group
          value={analisis_tipo}
          onChange={(e) => onFormChange("analisis_tipo", e.target.value)}
        >
          <Radio value="Probabilidad de Robo">Probabilidad de Robo</Radio>
          <Radio value="Estadística">Estadística</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Fecha">
        <DatePicker
          format="YYYY-MM-DD"
          value={date ? dayjs(date, "YYYY-MM-DD") : null}
          onChange={(date, dateString) => onFormChange("date", dateString)}
        />
      </Form.Item>
      <Form.Item label="Hora">
        <TimePicker
          format="HH:mm"
          value={time ? dayjs(time, "HH:mm") : null}
          onChange={(time, timeString) => onFormChange("time", timeString)}
        />
      </Form.Item>
      <Form.Item label="Mercancía">
        <Select
          value={mercancia}
          onChange={(value) => onFormChange("mercancia", value)}
          style={{ width: 200 }}
        >
          {tipoDeMercancias?.map((tipo) => (
            <Option key={tipo.id} value={tipo.nombre}>
              {tipo.nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Tipo de Vehículo">
        <Select
          value={tipoVehiculo}
          onChange={(value) => onFormChange("tipoVehiculo", value)}
          style={{ width: 200 }}
        >
          {tipoDeVehiculos?.map((tipo) => (
            <Option key={tipo.id} value={tipo.nombre}>
              {tipo.nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={applyFilters}>
          Aplicar Filtros
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChatFilters;
