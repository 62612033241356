import React, { useState, useEffect, useContext } from "react";
import { Drawer, Menu } from "antd";
import { MainContext } from "../contexts/MainContext";
import WinBox from "react-winbox";

import UserAdmin from '../components/admin/UserAdmin';
import TramosCarreterosAdmin from '../components/admin/TramosCarreterosAdmin';
import ConfiguracionVehicularAdmin from "./admin/ConfiguracionVehicularAdmin";
import InformeDeRobosAdmin from "./admin/InformeDeRobosAdmin";
import EstadosAdmin from "./admin/EstadosAdmin";
import DesenlacesAdmin from "./admin/DesenlacesAdmin";
import HechosAdmin from "./admin/HechosAdmin";
import TipoDeMercanciasAdmin from "./admin/TipoDeMercanciasAdmin";
import TipoDeVehiculosAdmin from "./admin/TipoDeVehiculosAdmin";
import CapasAdmin from "./admin/CapasAdmin";
//import ComponentForOption4 from "../components/ComponentForOption4";

export const MenuAdmin = () => {
  const { mostrarMenu, setMostrarMenu } = useContext(MainContext);
  const [windows, setWindows] = useState({});

  const [windowDimensions, setWindowDimensions] = useState({ width: '1200px', height: '600px' });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: `${window.innerWidth * 0.8}px`, // 70% of window width
        height: `${window.innerHeight * 0.7}px`, // 70% of window height
      });
    }

    window.addEventListener('resize', handleResize);

    // Set initial size
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const hideMenu = () => {
    setMostrarMenu(false);
  };

const openAdminWindow = (key, title, ContentComponent) => {
    if (windows[key]) return; // Prevent duplicate windows

    const newWindow = (
      <WinBox
        title={title}
        key={key}
        className="modern"
        x="center"
        y="center"
        width={windowDimensions.width}
        height={windowDimensions.height}
        onClose={() => closeWindow(key)}
      >
        <ContentComponent />
      </WinBox>
    );

    setWindows({ ...windows, [key]: newWindow });
    hideMenu(); // Close the menu after opening a window
  };

  const closeWindow = (key) => {
    const updatedWindows = { ...windows };
    delete updatedWindows[key];
    setWindows(updatedWindows);
  };

  return (
    <div>
      <Drawer
        title="Menu Administrador"
        placement={"right"}
        closable={false}
        onClose={hideMenu}
        open={mostrarMenu}
      >
        <Menu
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
        >
          <Menu.Item
            key="usuariosmenu"
            onClick={() =>
              openAdminWindow("winboxusuarios", "Usuarios", UserAdmin)
            }
          >
            Usuarios
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={() =>
              openAdminWindow("4", "Tramos Carreteros", TramosCarreterosAdmin)
            }
          >
            Tramos Carreteros
          </Menu.Item>

          <Menu.Item
            key="5"
            onClick={() =>
              openAdminWindow("5", "Reportes de robos", InformeDeRobosAdmin)
            }
          >
            Reportes de robos
          </Menu.Item>

          <Menu.Item
            key="6"
            onClick={() =>
              openAdminWindow("6", "Configuracion Vehicular", ConfiguracionVehicularAdmin)
            }
          >
            Configuracion Vehicular
          </Menu.Item>

          <Menu.Item
            key="7"
            onClick={() =>
              openAdminWindow("7", "Estados", EstadosAdmin)
            }
          >
            Estados
          </Menu.Item>

          <Menu.Item
            key="8"
            onClick={() =>
              openAdminWindow("8", "Desenlaces", DesenlacesAdmin)
            }
          >
            Desenlaces
          </Menu.Item>

          <Menu.Item
            key="9"
            onClick={() =>
              openAdminWindow("9", "Hechos", HechosAdmin)
            }
          >
            Hechos
          </Menu.Item>

          <Menu.Item
            key="10"
            onClick={() =>
              openAdminWindow("10", "Tipo de Mercancias", TipoDeMercanciasAdmin)
            }
          >
            Tipo de Mercancias
          </Menu.Item>

          <Menu.Item
            key="11"
            onClick={() =>
              openAdminWindow("11", "Tipo de Vehiculos", TipoDeVehiculosAdmin)
            }
          >
            Tipo de Vehiculos
          </Menu.Item>
          <Menu.Item
            key="12"
            onClick={() =>
              openAdminWindow("12", "Capas", CapasAdmin)
            }
          >
            Capas
          </Menu.Item>
        </Menu>
      </Drawer>
      {Object.values(windows)}
    </div>
  );
};

export default MenuAdmin;
