export function legsToList(legs) {
  let polyList = [];
  legs.forEach((steps) => {
    let st = steps.steps;
    st.forEach((body) => {
      polyList.push(body.polyline.points);
    });
  });

  return polyList;
}

export function routeDirections(legs) {
  let directions = [];
  legs.forEach((steps) => {
    let st = steps.steps;
    st.forEach((body) => {
      directions.push({
        instruction: body.html_instructions,
        title: body.maneuver || "Instrucción",
      });
    });
  });

  return directions;
}

export function toLineStringSingle(routes, origen, destino, tramo) {
  let lineString = {
    origen: origen,
    destino: destino,
    tramo: tramo + 1,
    tipo_escala: 3,
    ruta: {
      type: "LineString",
    },
  };

  let coords = routes.map((r) => [r.lng, r.lat]);
  lineString.ruta["coordinates"] = coords;

  return lineString;
}

export function splitArray(arr) {
  let temp = [];

  for (let i = 0; i < arr.length - 1; i++) {
    temp.push([arr[i], arr[i + 1]]);
  }

  return temp;
}

export function legsTo2D(legs) {
  let polyList = [];
  legs.forEach((steps) => {
    let st = steps.steps;
    let temp = [];
    st.forEach((body) => {
      temp.push(body.polyline.points);
    });
    polyList.push(temp);
  });

  return polyList;
}

export function decode(encoded) {
  var points = [];
  var index = 0,
    len = encoded.length;
  var lat = 0,
    lng = 0;
  while (index < len) {
    var b,
      shift = 0,
      result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63; //finds ascii
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;
    shift = 0;
    result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    points.push({ lat: lat / 1e5, lng: lng / 1e5 });
  }
  return points;
}
