import { React } from "react";
import styled from "styled-components";

const MarkSvg = styled.svg`
  fill: #ea4335;
  height: 24px;
`;

const MarkContainer = styled.div`
  width: 24px;
  display:grid;
  justify-content: center;
  align-content: center;
`;

export const Mark = ({ letter }) => {
  return (
    <MarkContainer>
      <MarkSvg viewBox="0 0 27 43">
        <defs>
          <path
            id="a"
            d="M12.5 0C5.5961 0 0 5.5961 0 12.5c0 1.8859.54297 3.7461 1.4414 5.4617 3.425 6.6156 10.216 13.566 10.216 22.195 0 .46562.37734.84297.84297.84297s.84297-.37734.84297-.84297c0-8.6289 6.7906-15.58 10.216-22.195.89844-1.7156 1.4414-3.5758 1.4414-5.4617 0-6.9039-5.5961-12.5-12.5-12.5z"
          />
        </defs>
        <g fill="" fill-rule="evenodd">
          <g transform="translate(1 1)">
            <use fill="#EA4335" />
            <path
              d="M12.5-.5c7.18 0 13 5.82 13 13 0 1.8995-.52398 3.8328-1.4974 5.6916-.91575 1.7688-1.0177 1.9307-4.169 6.7789-4.2579 6.5508-5.9907 10.447-5.9907 15.187 0 .74177-.6012 1.343-1.343 1.343s-1.343-.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-.97449-1.8608-1.4985-3.7942-1.4985-5.6937 0-7.18 5.82-13 13-13z"
              stroke="#fff"
            />
          </g>
          <text
            text-anchor="middle"
            dy=".3em"
            x="14"
            y="15"
            font-family="Roboto, Arial, sans-serif"
            font-size="16px"
            fill="#FFF"
          >
            {letter}
          </text>
        </g>
      </MarkSvg>
    </MarkContainer>
  );
};
