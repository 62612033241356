import React, { useState, useEffect } from "react";
import { Api } from "../../api";
import { Table, Button, Modal, Form, Input, Switch } from "antd";

const UserAdmin = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editForm] = Form.useForm();
  const [addForm] = Form.useForm();

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const fetchedUsers = await Api.Users.getAll();
    setUsers(fetchedUsers);
  };

  const showEditModal = (user) => {
    setEditingUser(user);
    editForm.setFieldsValue(user);
    setIsModalVisible(true);
  };

  const showAddUserModal = () => {
    setIsAddModalVisible(true);
    addForm.resetFields(); // Reset add form fields when opening the modal
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingUser(null);
  };

  const handleAddUser = async () => {
    try {
      const newUser = await addForm.validateFields();
      await Api.Users.create(newUser);
      loadUsers();
      setIsAddModalVisible(false);
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleEditUser = async () => {
    try {
      await editForm.validateFields();
      const values = {
        ...editForm.getFieldsValue(),
        active: editForm.getFieldValue("active") ? 1 : 0,
      };
      await Api.Users.update(editingUser.id, values);
      loadUsers();
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Correo Electrónico",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Nivel",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Activo",
      dataIndex: "active",
      key: "active",
      render: (active) => (active ? "Sí" : "No"),
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_, record) => (
        <Button onClick={() => showEditModal(record)}>Editar</Button>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#002146" }}>
      <Button type="primary" onClick={showAddUserModal}>
        Agregar Nuevo Usuario
      </Button>

      <Table size="small" dataSource={users} columns={columns} rowKey="id" />

      <Modal
        title="Editar Usuario"
        open={isModalVisible}
        onOk={handleEditUser}
        onCancel={handleCancel}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            name="email"
            label="Correo Electrónico"
            rules={[
              {
                required: true,
                message: "Por favor, ingrese el correo electrónico!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Contraseña"
            rules={[
              {
                required: false,
                message: "Por favor, ingrese una contraseña!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="level"
            label="Nivel"
            rules={[
              { required: true, message: "Por favor, ingrese el nivel!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="active" label="Activo" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Agregar Nuevo Usuario"
        open={isAddModalVisible}
        onOk={handleAddUser}
        onCancel={() => setIsAddModalVisible(false)}
      >
        <Form form={addForm} layout="vertical">
          <Form.Item
            name="email"
            label="Correo Electrónico"
            rules={[
              {
                required: true,
                message: "Por favor, ingrese el correo electrónico!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Contraseña"
            rules={[
              { required: true, message: "Por favor, ingrese una contraseña!" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="level"
            label="Nivel"
            rules={[
              { required: true, message: "Por favor, ingrese el nivel!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="active" label="Activo" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserAdmin;
