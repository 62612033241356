import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, notification } from 'antd';

const EnumsAdmin = ({ enumType, apiService }) => {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await apiService.getAll();
      setData(response);
    } catch (error) {
      notification.error({
        message: `Error cargando ${enumType}`,
        description: error.toString(),
      });
    }
  };

  const showModal = (item) => {
    setCurrentItem(item);
    form.setFieldsValue(item ? item : {});
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (currentItem) {
        await apiService.update(currentItem.id, values);
      } else {
        await apiService.create(values);
      }
      loadData();
      setIsModalVisible(false);
      notification.success({
        message: `Exito`,
        description: `${enumType} ha sido ${currentItem ? 'actualizado' : 'agregado'}.`,
      });
    } catch (error) {
      notification.error({
        message: `Error`,
        description: `Hubo un error ${currentItem ? 'actualizando' : 'agregando'} ${enumType}.`,
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentItem(null);
  };

  const deleteItem = async (id) => {
    try {
      await apiService.delete(id);
      loadData();
      notification.success({
        message: 'Exito',
        description: `${enumType} fue exitosamente eliminado.`,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `Hubo un error eliminando ${enumType}.`,
      });
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button onClick={() => showModal(record)}>Editar</Button>
          <Button danger onClick={() => deleteItem(record.id)}>Eliminar</Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={() => showModal(null)}>
        Agregar nuevo {enumType}
      </Button>
      <Table dataSource={data} columns={columns} rowKey="id" />
      <Modal title={currentItem ? 'Editar' : 'Agregar'} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Form.Item name="nombre" label="Nombre" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EnumsAdmin;
