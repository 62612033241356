import React, { useState, useContext, useEffect } from "react";
import {
  EyeTwoTone,
  EyeInvisibleTwoTone,
  CloseSquareTwoTone,
  MenuOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import {  Tag, Table, Layout, Alert, Space } from "antd";
import { MainContext } from "../contexts/MainContext";
import * as turf from "@turf/turf";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));
const { Content } = Layout;

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const TramoTable = ({ tramo, tramok, ...props }) => {
  const [tramosRuta, setTramosRuta] = useState([]);
  const [interseccionesRuta, setInterseccionesRuta] = useState([]);

  const { permisos, setPermisos } = useContext(MainContext);


  const permisosLinks = [
    { text: "---", url: "" },
    {
      text: "Solicite permiso de conectividad entre ejes carreteros",
      url: "https://www.sct.gob.mx/fileadmin/DireccionesGrales/DGAF/Nom012/Conectividad_dos_ejes_o_tramos_mismo_eje.pdf",
    },
    {
      text: "Solicite un permiso de conectividad de entrada y salida",
      url: "https://www.sct.gob.mx/fileadmin/DireccionesGrales/DGAF/Nom012/Conectividad_llegar_o_salir.pdf",
    },
    { text: "No puede circular, consulte rutas alternas", url: "" },
  ];

  const columnas = [
    {
      title: "Orden",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "No.",
      key: "no",
      dataIndex: "no",
    },
    {
      title: "Ruta",
      key: "ruta",
      dataIndex: "ruta",
    },
    {
      title: "Tramo",
      key: "tramo",
      dataIndex: "tramo",
    },
    {
      title: "Tipo",
      key: "tipo",
      dataIndex: "tipo",
    },
    {
      title: "Permisos",
      key: "permisos",
      dataIndex: "permisos",
      render: (permisos) => (
        <>
          {permisos?.map((permiso, index) => {
            const permisoLink = permisosLinks[permiso];
            if (permisoLink.url) {
              return (
                <a
                  href={permisoLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  style={{ marginRight: 8 }}
                >
                  <Tag
                    color={
                      permiso === 0
                        ? "green"
                        : permiso === 1 || permiso === 2
                        ? "blue"
                        : "red"
                    }
                  >
                    {permisoLink.text} <LinkOutlined />
                  </Tag>
                </a>
              );
            } else {
              return (
                <Tag color="green" key={index}>
                  ---
                </Tag>
              );
            }
          })}
        </>
      ),
    },
    {
      title: "PBV / Largo máx.",
      key: "dimensiones",
      dataIndex: "dimensiones",
      render: (d, r) => {
        return (
          <>
            {`${d?.peso ?? "0"}`} ton / {`${d?.largo ?? "0"}`} m
          </>
        );
      },
    },
    {
      title: "Longitud",
      key: "longitud",
      dataIndex: "puntos",
      render: (d, r) => {
        console.log(d, r);
        const length = turf.length(d, { units: "kilometers" });
        return <>{`${length.toFixed(2) ?? "0"} km`}</>;
      },
    },
  ];

  const columnasRutas = [
    ...columnas,
    {
      title: "Operaciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (a, r, index, c) => (
        <Space size="middle">
          {r.show() ? (
            <EyeTwoTone onClick={() => mostrarTramo(index, "ruta")} />
          ) : (
            <EyeInvisibleTwoTone
              onClick={() => ocultarTramo(index, "ruta")}
              twoToneColor="#eb2f96"
            />
          )}
          <CloseSquareTwoTone
            onClick={() => borrarTramo(index, "ruta")}
            twoToneColor="#eb2f96"
          />
        </Space>
      ),
    },
  ];

  const columnasIntersecciones = [
    ...columnas,
    {
      title: "Operaciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (a, r, index) => {
        return (
          <Space size="middle">
            {r.show() ? (
              <EyeTwoTone onClick={() => mostrarTramo(index, "interseccion")} />
            ) : (
              <EyeInvisibleTwoTone
                onClick={() => ocultarTramo(index, "interseccion")}
                twoToneColor="#eb2f96"
              />
            )}
            <CloseSquareTwoTone
              onClick={() => borrarTramo(index, "interseccion")}
              twoToneColor="#eb2f96"
            />
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("tramo changed", tramo);
    console.log("tramo changed", tramo.tramos);
    setTramosRuta(tramo.tramos);
    setInterseccionesRuta(tramo.intersecciones);
  }, [tramo]);

  const borrarTramo = (index, tipo) => {
    const newPermisos = permisos.map((permiso) => {
      if (permiso.tramo_num === tramo.tramo_num) {
        tipo === "ruta" && permiso.tramos.splice(index, 1);
        tipo === "interseccion" && permiso.intersecciones.splice(index, 1);
      }
      return permiso;
    });
    setPermisos(newPermisos);
  };

  const mostrarTramo = (index, tipo) => {
    const newPermisos = permisos.map((permiso) => {
      if (permiso.tramo_num === tramo.tramo_num) {
        tipo === "ruta" && (permiso.tramos[index].show = () => false);
        tipo === "interseccion" &&
          (permiso.intersecciones[index].show = () => false);
      }
      return permiso;
    });
    setPermisos(newPermisos);
  };

  const ocultarTramo = (index, tipo) => {
    const newPermisos = permisos.map((permiso) => {
      if (permiso.tramo_num === tramo.tramo_num) {
        tipo === "ruta" && (permiso.tramos[index].show = () => true);
        tipo === "interseccion" &&
          (permiso.intersecciones[index].show = () => true);
      }
      return permiso;
    });
    setPermisos(newPermisos);
  };

  const onSortEnd = ({ oldIndex, newIndex }, tipo) => {
    if (oldIndex !== newIndex) {
      const newPermisos = permisos.map((permiso) => {
        if (permiso.tramo_num === tramo.tramo_num) {
          const tipoKey = tipo === "interseccion" ? "intersecciones" : "tramos";
          permiso[tipoKey] = arrayMoveImmutable(
            permiso[tipoKey],
            oldIndex,
            newIndex
          ).filter((el) => !!el);
        }
        return permiso;
      });
      setPermisos(newPermisos);
    }
  };

  const DraggableContainer = (props, tipo) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={(p) => {
        onSortEnd(p, tipo);
      }}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }, dataSource) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.tramo === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <>
      <Content style={{ padding: 12 }}>
        {tramosRuta && tramosRuta.length > 0 ? (
          <Table
            pagination={false}
            dataSource={tramosRuta}
            columns={columnasRutas}
            rowKey={(r) => r.tramo}
            components={{
              body: {
                wrapper: (p) => DraggableContainer(p, "ruta"),
                row: (p) => DraggableBodyRow(p, tramosRuta),
              },
            }}
          />
        ) : (
          <Alert
            message="Tramos de ruta"
            description="No se encontraron tramos para esta ruta."
            type="warning"
            showIcon
          />
        )}
        {interseccionesRuta && interseccionesRuta.length > 0 ? (
          <Table
            pagination={false}
            dataSource={interseccionesRuta}
            columns={columnasIntersecciones}
            rowKey={(r) => r.tramo}
            components={{
              body: {
                wrapper: (p) => DraggableContainer(p, "interseccion"),
                row: (p) => DraggableBodyRow(p, interseccionesRuta),
              },
            }}
          />
        ) : (
          <Alert
            message="Tramos de intersección"
            description="No se encontraron tramos de intersección para esta ruta."
            type="info"
            showIcon
          />
        )}
      </Content>

    </>
  );
};
