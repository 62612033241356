import { create } from "apisauce";
const BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://127.0.0.1:3333";
// define the api
const instance = create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

// Function to set the Authorization header
export const setAuthToken = (token) => {
  instance.setHeader("Authorization", `Bearer ${token}`);
};

// Retrieve the token from localStorage and set it
const token = window.localStorage.getItem("token");
if (token) {
  setAuthToken(token);
}

const responseData = (res) => res.data;

const errorHandling = (err) => {
  console.error(err);
  return Promise.reject(err);
};

const requests = {
  post: (url, body) =>
    instance.post(`${url}`, body).then(responseData).catch(errorHandling),
  get: (url) => instance.get(`${url}`).then(responseData).catch(errorHandling),
  put: (url, body) =>
    instance.put(`${url}`, body).then(responseData).catch(errorHandling),
  delete: (url) =>
    instance.delete(`${url}`).then(responseData).catch(errorHandling),
};
export const Directions = {
  getRoute: (body) => requests.post("/directions/route", body),
  getRouteWayponts: (body) =>
    requests.post("/directions/route_waypoints", body),
  getPoint: (body) => requests.post("/directions/point", body),
};

export const Auth = {
  login: ({ email, password }) => requests.post("/login", { email, password }),
};

export const Permisos = {
  getPermisosByRoutes: (rutas) => requests.post("/permisos/by_routes", rutas),
};

export const Capas = {
  getCapasByToken: async (token) =>
    await requests.get(`/capas/by_token/${token}`),
  getAll: async () => await requests.get("/capas/"),
  // List all registered capas
  listRegistered: async () => await requests.get("/capas/list-registered"),

  // Register an existing table as a capa
  register: async (capaData) =>
    await requests.post("/capas/register", capaData),

  // List all unregistered capas (tables with a geom column not in capas)
  listUnregistered: async () => await requests.get("/capas/unregistered"),

  // Add a new capa and optionally create its table
  addNewCapaAndTable: async (capaTableData) =>
    await requests.post("/capas/add", capaTableData),

  // Remove a capa and its table by tableName
  removeTableAndCapa: async (tableName) =>
    await requests.delete(`/capas/remove/${tableName}`),

  // Show details for a specific capa by ID
  getById: async (id) => await requests.get(`/capas/${id}`),

  // Update a capa's details by ID
  update: async (id, capaData) => await requests.put(`/capas/${id}`, capaData),

  // Delete a capa by ID
  deleteById: async (id) => await requests.delete(`/capas/${id}`),
  unregisterByToken: async (token) => await requests.delete(`/capas/by-token/${token}`),
  updateByToken: async (token, capaData) => await requests.put(`/capas/by-token/${token}`, capaData),

};

export const Menu = {
  getMenus: async () => await requests.get("/menu/all_menus"),
  getMenuCapaByIdToken: async (idtoken) =>
    await requests.get(`/menu/sub_menu_capas/${idtoken}`),
};

export const Users = {
  getAll: async () => await requests.get("/users"),
  getById: async (id) => await requests.get(`/users/${id}`),
  create: async (userData) => await requests.post("/users", userData),
  update: async (id, userData) => await requests.put(`/users/${id}`, userData),
  delete: async (id) => await requests.delete(`/users/${id}`),
};

export const ConfiguracionesVehiculares = {
  getAll: async () => await requests.get("/configuraciones-vehiculares"),
  getById: async (id) =>
    await requests.get(`/configuraciones-vehiculares/${id}`),
  create: async (userData) =>
    await requests.post("/configuraciones-vehiculares", userData),
  update: async (id, userData) =>
    await requests.put(`/configuraciones-vehiculares/${id}`, userData),
  delete: async (id) =>
    await requests.delete(`/configuraciones-vehiculares/${id}`),
};

export const InformesDeRobos = {
  getAll: async () => await requests.get("/informes-de-robos"),
  getById: async (id) => await requests.get(`/informes-de-robos/${id}`),
  create: async (userData) =>
    await requests.post("/informes-de-robos", userData),
  update: async (id, userData) =>
    await requests.put(`/informes-de-robos/${id}`, userData),
  delete: async (id) => await requests.delete(`/informes-de-robos/${id}`),
};

export const Rutas = {
  getAll: async (page = 1, limit = 10) =>
    await requests.get(`/rutas?page=${page}&limit=${limit}`),
  getById: async (id) => await requests.get(`/rutas/${id}`),
  listSimple: async () => await requests.get("/rutas/simple"),
  update: async (id, routeData) =>
    await requests.put(`/rutas/${id}`, routeData),
  insert: async (routeData) => await requests.post("/rutas", routeData),
  updateRoutePath: async (id, newPath) =>
    await requests.put(`/rutas/${id}/updatePath`, { newPath }),
  delete: async (id) => await requests.delete(`/rutas/${id}`),
};

export const Enums = {
  Estados: {
    getAll: async () => await requests.get("/admin/estados"),
    create: async (data) => await requests.post("/admin/estados", data),
    update: async (id, data) =>
      await requests.put(`/admin/estados/${id}`, data),
    delete: async (id) => await requests.delete(`/admin/estados/${id}`),
  },
  Desenlaces: {
    getAll: async () => await requests.get("/admin/desenlaces"),
    create: async (data) => await requests.post("/admin/desenlaces", data),
    update: async (id, data) =>
      await requests.put(`/admin/desenlaces/${id}`, data),
    delete: async (id) => await requests.delete(`/admin/desenlaces/${id}`),
  },
  Hechos: {
    getAll: async () => await requests.get("/admin/hechos"),
    create: async (data) => await requests.post("/admin/hechos", data),
    update: async (id, data) => await requests.put(`/admin/hechos/${id}`, data),
    delete: async (id) => await requests.delete(`/admin/hechos/${id}`),
  },
  TipoDeMercancias: {
    getAll: async () => await requests.get("/admin/tipo-de-mercancias"),
    create: async (data) =>
      await requests.post("/admin/tipo-de-mercancias", data),
    update: async (id, data) =>
      await requests.put(`/admin/tipo-de-mercancias/${id}`, data),
    delete: async (id) =>
      await requests.delete(`/admin/tipo-de-mercancias/${id}`),
  },
  TipoDeVehiculos: {
    getAll: async () => await requests.get("/admin/tipo-de-vehiculos"),
    create: async (data) =>
      await requests.post("/admin/tipo-de-vehiculos", data),
    update: async (id, data) =>
      await requests.put(`/admin/tipo-de-vehiculos/${id}`, data),
    delete: async (id) =>
      await requests.delete(`/admin/tipo-de-vehiculos/${id}`),
  },
};

export const Api = {
  instance,
  Auth,
  Directions,
  Permisos,
  Capas,
  Menu,
  Users,
  Rutas,
  ConfiguracionesVehiculares,
  InformesDeRobos,
  Enums,
};

export default Api;
