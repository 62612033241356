import React, { useContext, useRef, useState } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Form, Button, Space, Select, Avatar, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { MainContext } from "../contexts/MainContext";
import Autocomplete from "react-google-autocomplete";
import { Rnd } from "react-rnd";
import { Tag } from "antd";
import { FaWindowMinimize, FaWindowMaximize } from "react-icons/fa";
import { DragOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Buscar } from "./Buscar";
import { Mark } from "./Mark";

import { CaretRightFilled } from "@ant-design/icons";
const { Option } = Select;

const EscalaForm = styled(Form)`
  justify-content: center;
  flex-direction: row;
  align-items: center;
  overflow: auto;
`;

const AvatarRnd = styled(Rnd)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  background: #002140;
  border-radius: 5px;
  box-shadow: 1px 1px 5px black;
  z-index: 99999;
`;

const PageHeaderStyled = styled(PageHeader)`
  && {
    padding: 0 5px;
    margin: -10px -10px 0px;
  }
`;

const RndStyled = styled(Rnd)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: #002140;
  border-radius: 5px;
  box-shadow: 1px 1px 5px black;
`;

const camiones = [
  {
    text: "Sin vehiculo",
    value: 0,
    selected: false,
    description: "Sin vehiculo",
    width: "90px",
    height: "90px",
  },
  {
    text: "B2",
    value: 1,
    selected: false,
    description: "2 Ejes, 6 Llantas",
    width: "260px",
    height: "90px",
  },
  {
    text: "B3",
    value: 2,
    selected: false,
    description: "3 Ejes, 8 Llantas",
    width: "260px",
    height: "90px",
  },
  {
    text: "B3",
    value: 38,
    selected: false,
    description: "3 Ejes, 10 Llantas",
    width: "260px",
    height: "90px",
  },
  {
    text: "B4",
    value: 3,
    selected: false,
    description: "4 Ejes, 10 Llantas",
    width: "260px",
    height: "90px",
  },
  {
    text: "C2",
    value: 4,
    selected: false,
    description: "2 Ejes, 6 Llantas",
    width: "230px",
    height: "90px",
  },
  {
    text: "C3",
    value: 5,
    selected: false,
    description: "3 Ejes, 8 Llantas",
    width: "230px",
    height: "90px",
  },
  {
    text: "C3",
    value: 39,
    selected: false,
    description: "3 Ejes, 10 Llantas",
    width: "230px",
    height: "90px",
  },
  {
    text: "C2-R2",
    value: 6,
    selected: false,
    description: "4 Ejes, 14 Llantas",
    width: "310px",
    height: "90px",
  },
  {
    text: "C3-R2",
    value: 7,
    selected: false,
    description: "5 Ejes, 18 Llantas",
    width: "310px",
    height: "90px",
  },
  {
    text: "C2-R3",
    value: 8,
    selected: false,
    description: "5 Ejes, 18 Llantas",
    width: "310px",
    height: "90px",
  },
  {
    text: "C3-R3",
    value: 9,
    selected: false,
    description: "6 Ejes, 22 Llantas",
    width: "310px",
    height: "90px",
  },
  {
    text: "T2-S1",
    value: 10,
    selected: false,
    description: "3 Ejes, 10 Llantas",
    width: "330px",
    height: "90px",
  },
  {
    text: "T2-S2",
    value: 11,
    selected: false,
    description: "4 Ejes, 14 Llantas",
    width: "330px",
    height: "90px",
  },
  {
    text: "T2-S3",
    value: 12,
    selected: false,
    description: "5 Ejes, 18 Llantas",
    width: "330px",
    height: "90px",
  },
  {
    text: "T3-S1",
    value: 13,
    selected: false,
    description: "4 Ejes, 14 Llantas",
    width: "330px",
    height: "90px",
  },
  {
    text: "T3-S2",
    value: 14,
    selected: false,
    description: "5 Ejes, 18 Llantas",
    width: "330px",
    height: "90px",
  },
  {
    text: "T3-S3",
    value: 15,
    selected: false,
    description: "6 Ejes, 22 Llantas",
    width: "330px",
    height: "90px",
  },
  {
    text: "T2-S1-R2",
    value: 27,
    selected: false,
    description: "5 Ejes, 18 Llantas",
    width: "400px",
    height: "90px",
  },
  {
    text: "T2-S2-R2",
    value: 28,
    selected: false,
    description: "6 Ejes, 22 Llantas",
    width: "400px",
    height: "90px",
  },
  {
    text: "T2-S1-R3",
    value: 29,
    selected: false,
    description: "6 Ejes, 22 Llantas",
    width: "400px",
    height: "90px",
  },
  {
    text: "T3-S1-R2",
    value: 30,
    selected: false,
    description: "6 Ejes, 22 Llantas",
    width: "400px",
    height: "90px",
  },
  {
    text: "T3-S1-R3",
    value: 31,
    selected: false,
    description: "7 Ejes, 26 Llantas",
    width: "400px",
    height: "90px",
  },
  {
    text: "T3-S2-R2",
    value: 32,
    selected: false,
    description: "7 Ejes, 26 Llantas",
    width: "400px",
    height: "90px",
  },
  {
    text: "T3-S2-R3",
    value: 33,
    selected: false,
    description: "8 Ejes, 30 Llantas",
    width: "400px",
    height: "90px",
  },
  {
    text: "T3-S2-R4",
    value: 34,
    selected: false,
    description: "9 Ejes, 34 Llantas",
    width: "400px",
    height: "90px",
  },
  {
    text: "T2-S2-S2",
    value: 35,
    selected: false,
    description: "6 Ejes, 22 Llantas",
    width: "400px",
    height: "90px",
  },
  {
    text: "T3-S2-S2",
    value: 36,
    selected: false,
    description: "7 Ejes, 26 Llantas",
    width: "400px",
    height: "90px",
  },
  {
    text: "T3-S3-S2",
    value: 37,
    selected: false,
    description: "8 Ejes, 30 Llantas",
    width: "400px",
    height: "90px",
  },
];

const EscalaRow = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: 0.2fr 1.3fr 0.3fr 1.2fr 2.3fr 0.5fr;
  grid-template-rows: 1fr;
  gap: 0px 10px;
  grid-template-areas: ". . . . . .";
`;

export const Escalas = (props) => {
  const [form] = Form.useForm();
  const { setShowing } = props;
  const {
    origen,
    destino,
    escalas,
    setEscalas,
    escalasTipos,
    setEscalasTipos,
    escalasCamiones,
    setEscalasCamiones,
    camion,
    setCamion,
  } = useContext(MainContext);

  const [lastEscala, setLastEscala] = useState(undefined);

  let addEscala = undefined;

  const onFinish = ({ escalas = [], camionRemanente, camionOrigen }) => {
    console.log(escalas)
    const camiones = escalas.map(({ camionEscala }) => camionEscala);
    camiones.unshift(camionOrigen);

    setEscalas(escalas.map(({ escala }) => ({ location: escala.address })));
    setEscalasTipos(escalas.map(({ escalatipo }) => escalatipo));
    setEscalasCamiones(camiones);
  };

  const [compStyle, setCompStyle] = useState({
    x: 80,
    y: 80,
    width: "auto",
    height: "auto",
    minHeight: 250,
  });
  const [fullSize, setFullSize] = useState(false);

  return (
    <RndStyled
      style={{ display: props.display }}
      size={fullSize && { width: compStyle.width, height: compStyle.height }}
      position={{ x: compStyle.x, y: compStyle.y }}
      default={compStyle}
      onResizeStop={(e, dir, ref, delta, position) => {
        setCompStyle({
          ...compStyle,
          width: compStyle.width + delta.width,
          height: compStyle.height + delta.height,
        });
      }}
      onDragStop={(a, b) => setCompStyle({ ...compStyle, x: b.x, y: b.y })}
    >
      <PageHeaderStyled
        ghost={false}
        subTitle="Escalas"
        extra={[
          <FaWindowMinimize
            style={{ cursor: "pointer" }}
            onClick={() => setShowing(false)}
          />,
          <FaWindowMaximize
            style={{ cursor: "pointer" }}
            onClick={() => {
              fullSize
                ? setCompStyle({
                    x: 80,
                    y: 80,
                    width: "auto",
                    height: "auto",
                    minHeight: 250,
                  })
                : setCompStyle({
                    x: 0,
                    y: 0,
                    width: "100%",
                    height: "100%",
                    minHeight: 250,
                  });
              setFullSize(!fullSize);
            }}
          />,
        ]}
      ></PageHeaderStyled>

      <EscalaForm form={form} name="escalas_form" onFinish={onFinish}>
        <EscalaRow>
          <Mark letter="A" />
          <Input
            style={{ gridColumnStart: "span 2" }}
            value={origen}
            disabled
          />
          <Select placeholder="Tipo" value={1} disabled>
            <Option value={1}> - </Option>
          </Select>

          <Form.Item
            style={{ marginBottom: "0px" }}
            initialValue={camion}
            name="camionOrigen"
            fieldKey="camionOrigen"
          >
            <Select placeholder={"Seleccionar Camion"}>
              {camiones.map((camion, i) => (
                <Option key={`escala_rem_origen${i}`} value={camion.value}>
                  Vehículo: {camion.text} - {camion.description}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag style={{ marginRight: 0 }} color="#177ddc">
              A
            </Tag>
            <CaretRightFilled />
            <Tag style={{ marginRight: 0 }} color="#177ddc">
              B
            </Tag>
          </div>
        </EscalaRow>

        <Form.List name="escalas">
          {(fields, { add, remove }) => {
            addEscala = add;
            return (
              <>
                {fields.map((field, fieldIndex) => {
                  return (
                    <EscalaRow
                      key={`escalaslist_${field.key}`}
                      align="baseline"
                    >
                      <Mark letter={String.fromCharCode(65 + fieldIndex + 1)} />
                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        {...field}
                        name={[field.name, "escala"]}
                        key={`${field.key}_escala`}
                        rules={[{ required: true, message: "Falta Escala" }]}
                      >
                        <Buscar
                          onChange={(oo) => {
                            console.log(oo);
                            fieldIndex + 1 === fields.length &&
                              setLastEscala(oo);
                          }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        style={{ color: "#EB494B" }}
                        onClick={() => remove(field.name)}
                      />
                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        {...field}
                        initialValue={3}
                        name={[field.name, "escalatipo"]}
                        key={`${field.key}_escalatipo`}
                      >
                        <Select placeholder="Tipo">
                          <Option value={1}>Carga</Option>
                          <Option value={2}>Descarga</Option>
                          <Option value={3}>Punto de Interes</Option>
                          <Option value={4}>Carga / Descarga</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        {...field}
                        initialValue={camiones[0].value}
                        name={[field.name, "camionEscala"]}
                        key={`${field.key}_camionEscala`}
                      >
                        <Select placeholder={"Seleccionar Camion"}>
                          {camiones.map((camion, index) => (
                            <Option key={`${index}_camion__index`} value={camion.value}>
                              Vehículo: {camion.text} - {camion.description}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Tag style={{ marginRight: 0 }} color="#177ddc">
                          {String.fromCharCode(66 + fieldIndex)}
                        </Tag>
                        <CaretRightFilled />
                        <Tag style={{ marginRight: 0 }} color="#177ddc">
                          {String.fromCharCode(66 + fieldIndex + 1)}
                        </Tag>
                      </div>
                    </EscalaRow>
                  );
                })}
              </>
            );
          }}
        </Form.List>

        <EscalaRow>
          <Mark
            letter={`${String.fromCharCode(
              65 + form.getFieldsValue()?.escalas?.length + 1
            )}`}
          />
          <Input
            style={{ gridColumnStart: "span 2" }}
            value={destino}
            disabled
          />
          <Select placeholder="Tipo" value={1} disabled>
            <Option value={1}> - </Option>
          </Select>
          <Select placeholder={"Seleccionar Camion"} value={1} disabled>
            <Option value={1}> - </Option>
          </Select>
        </EscalaRow>

        <EscalaRow>
          <div style={{ gridColumnStart: 2, gridColumnEnd: 6 }}>
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => addEscala?.()}
                block
                icon={<PlusOutlined />}
              >
                Añadir Escala
              </Button>
            </Form.Item>
          </div>
        </EscalaRow>

        <div style={{ display: "flex", marginTop: "1em" }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Aplicar Escalas
            </Button>
          </Form.Item>
          {escalas.length > 0 && (
            <Button
              type="primary"
              danger
              style={{ margin: "0px 10px" }}
              onClick={() => {
                setEscalas([]);
                setEscalasTipos([]);
                setEscalasCamiones([]);
              }}
            >
              Reestablecer Escalas
            </Button>
          )}
        </div>
      </EscalaForm>
    </RndStyled>
  );
};

export default Escalas;
