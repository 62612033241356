import React, { useState, useEffect, useContext } from "react";
import { PageHeader} from "@ant-design/pro-layout";
import { Select,  Tooltip  } from "antd";
import styled from "styled-components";
import { Rnd } from "react-rnd";
import { MainContext } from "../contexts/MainContext";
import { FaWindowMinimize } from "react-icons/fa";
import { Api } from "../api";

const { Option } = Select;

const SelectStyled = styled(Select)`
  && .ant-select-dropdown .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }
`;

const PageHeaderStyled = styled(PageHeader)`
  && {
    padding: 0 5px;
    margin: -10px -10px 0px;
  }
`;

const RndStyled = styled(Rnd)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  background: #002140;
  border-radius: 5px;
  box-shadow: 1px 1px 5px black;
  z-index: 99999;
  overflow-y: auto;
  overflow-x: hidden;
`;

function importImages(r) {
  let images = {};
console.log(r.keys())
  r.keys().forEach((item) => {
    images[item.replace('./', '').replace('.png', '')] = r(item);
  });
  console.log(images)
  return images;
}


const images = importImages(require.context('../images/camiones', false, /\.(png)$/));

export const Camiones = ({ display, setShowing }) => {
  const { setCamion } = useContext(MainContext);
  const [configuraciones, setConfiguraciones] = useState([]);

  useEffect(() => {
    const fetchConfiguraciones = async () => {
      try {
        let fetchedConfiguraciones = await Api.ConfiguracionesVehiculares.getAll();
        // Sort by id
        fetchedConfiguraciones = fetchedConfiguraciones.sort((a, b) => a.id - b.id);
        setConfiguraciones(fetchedConfiguraciones);
      } catch (error) {
        console.error("Error fetching configuraciones:", error);
      }
    };

    fetchConfiguraciones();
  }, []);

  return (
    <RndStyled style={{ display }} default={{ x: 40, y: window.innerHeight / 3, width: 370, height: "auto", minHeight: 250 }}>
      <PageHeaderStyled ghost={false} subTitle="Vehiculos" extra={[<FaWindowMinimize key="minimize" style={{ cursor: "pointer" }} onClick={() => setShowing(false)} />]} />
      <SelectStyled placeholder="Seleccionar Vehículo" style={{ width: "90%" }} onChange={setCamion}>
        {configuraciones.map(({ id, configuracion_vehicular, numero_de_ejes, numero_de_llantas }) => (
          <Option key={id} value={id}>
            <Tooltip title={`${configuracion_vehicular} - ${numero_de_ejes} Ejes, ${numero_de_llantas} Llantas`}>
              <img src={images[configuracion_vehicular.toLowerCase()]} alt={configuracion_vehicular.toLowerCase()} style={{ marginRight: '8px', width: '50px', height: 'auto' }} />
              {`${configuracion_vehicular} - ${numero_de_ejes} Ejes, ${numero_de_llantas} Llantas`}
            </Tooltip>
          </Option>
        ))}
      </SelectStyled>
    </RndStyled>
  );
};

export default Camiones;
