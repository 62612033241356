import { useState, useEffect, useContext } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Spin, Typography, Avatar } from "antd";
import ReactJson from "react-json-view";
import styled from "styled-components";
import { FaWindowMaximize, FaWindowMinimize } from "react-icons/fa";
import ContenidoPermisos from "./Permisos";
import { Tabs, Space } from "antd";
import { Rnd } from "react-rnd";
import { MainContext } from "../contexts/MainContext";

import { DragOutlined, FullscreenOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;
const { Text, Link } = Typography;

const RndStyled = styled(Rnd)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  background: #002140;
  border-radius: 5px;
  box-shadow: 1px 1px 5px black;
  z-index: 99999;
  overflow-y: auto;
  overflow-x: hidden;
`;

const AvatarRnd = styled(Rnd)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  background: #002140;
  border-radius: 5px;
  box-shadow: 1px 1px 5px black;
  z-index: 99999;
`;

const PageHeaderStyled = styled(PageHeader)`
  color: white;
  && {
    padding: 0 5px;
    margin: -10px -10px 0px;
  }
`;

export const Permisos = (props) => {
  const { onClose, setShowing } = props;
  const { permisos, cargandoPermisos, camion, ruta } = useContext(MainContext);
  const [fullScreen, setFullScreen] = useState(false);
  const [jsonSmap, setJsonSmap] = useState();
  const [draggable, setDraggable] = useState(false);

  const downloadJSON = async () => {
    const fileName = "file";
    const json = JSON.stringify(jsonSmap);
    const blob = new Blob([json], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const permi = JSON.parse(JSON.stringify(permisos));
    const permisosMap = permi?.map((permiso) => {
      permiso.tramos = permiso.tramos.map((tramo) => {
        delete tramo.puntos;
        return tramo;
      });

      permiso.intersecciones = permiso.intersecciones.map((tramo) => {
        delete tramo.puntos;
        delete tramo.polygon;
        return tramo;
      });

      return permiso;
    });
    setJsonSmap({ vehiculo: { tipo: camion }, permisos: permisosMap, ruta });
  }, [permisos, ruta]);

  const [compStyle, setCompStyle] = useState({
    x: 10,
    y: window.innerHeight / 2,
    height: "40vh",
  });
  const [fullSize, setFullSize] = useState(false);

  return (
    <RndStyled
      placement="bottom"
      closable="true"
      disableDragging={draggable}
      style={{ display: props.display }}
      dragHandleClassName={"smap-info"}
      onClose={onClose}
      size={ fullSize && { width: compStyle.width, height: compStyle.height }}
      position={{ x: compStyle.x, y: compStyle.y }}
      default={{
        x: 10,
        y: window.innerHeight / 2,
        height: "40vh",
      }}
      onResizeStop = {(e,dir,ref,delta,position)=> setCompStyle({...compStyle, width: compStyle.width+delta.width, height: compStyle.height+delta.height})}
      onDragStop = {(a,b)=> setCompStyle({...compStyle, x: b.x, y:b.y})}
    >
      <PageHeaderStyled
        className="smap-info"
        ghost={false}
        title="SMAP"
        subTitle="Información de tramos"
        extra={[
          <DragOutlined />,
          <FaWindowMinimize
            style={{ cursor: "pointer" }}
            onClick={() => setShowing(false)}
          />,
          <FaWindowMaximize
            style={{ cursor: "pointer" }}
            onClick={() => {
              fullSize
                ? setCompStyle({
                    x: 10,
                    y: window.innerHeight / 2,
                    height: "40vh",
                  })
                : setCompStyle({
                    x: 0,
                    y: 0,
                    width: "100%",
                    height: "100%",
                    minHeight: 250,
                  });
              setFullSize(!fullSize);
            }}
          />,
        ]}
      ></PageHeaderStyled>
      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={
          <>{permisos && <Button onClick={downloadJSON}>JSON</Button>}</>
        }
      >
        <TabPane tab="PERMISOS" key="1">
          <Spin tip="Cargando Permisos..." spinning={cargandoPermisos}>
            <ContenidoPermisos parent={{ draggable, setDraggable }} />
          </Spin>
        </TabPane>
        <TabPane tab="JSON" key="4">
          <ReactJson
            theme={"monokai"}
            displayDataTypes={false}
            src={jsonSmap}
          />
        </TabPane>
      </Tabs>
    </RndStyled>
  );
};

export default Permisos;
