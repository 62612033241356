import React, { Component } from 'react';
import { message } from 'antd';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so we can show an error message
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error("Caught an error:", error, errorInfo);

    // Display an error message
    message.error(`An error occurred: ${error.message}`);
  }

  render() {
    if (this.state.hasError) {
      // You can still render children components
      return this.props.children;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
