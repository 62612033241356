import React, { useEffect, useContext, useState, useCallback } from "react";
import { Table, Typography, Layout, Collapse, Alert, Spin, Button } from "antd";
import { Progress } from "antd";
import { RutasContext } from "../contexts/RutasContext";
import { MainContext } from "../contexts/MainContext";
import parse from "html-react-parser";
import Api from "../api";
import { TramoTable } from "./TramoTable";
import { useWhyDidYouUpdate } from 'ahooks';

const { Panel } = Collapse;

const ProgressBar = (props) => {
  const { percentage, final } = props;
  return (
    <>
      <Progress percent={percentage} />
      <p> Recorrido XXX.XX de {final}km</p>
    </>
  );
};

const getEscala = (tipo) => {
  switch (tipo) {
    case 1:
      return "Escala: Carga";
    case 2:
      return "Escala: Descarga";
    case 3:
      return "Escala: Punto de Interés";
    case 4:
      return "Escala: Carga/Descarga";
    case 0:
      return "";
    default:
      break;
  }
};

export const ContenidoPermisos = (props) => {
  const { rutas } = useContext(RutasContext);
  const {
    permisos,
    setPermisos,
    camion,
    camiones,
    cargandoPermisos,
    setCargandoPermisos,
    ruta,
    setRuta,
  } = useContext(MainContext);

  const [datat, setDataT] = useState([]);
  const [error, setError] = useState(null);

  useWhyDidYouUpdate('useWhyDidYouUpdateComponent', { ...props, permisos });

  const getPermisos = useCallback(async (retries = 3) => {
    console.log("Rutas:", rutas);
    setCargandoPermisos(true);
    setError(null);
    try {
      const resp = await Api.Permisos.getPermisosByRoutes({
        tramos: rutas.tramos,
        camion: camion || 0,
      });
      console.log('Permisos:', resp.permisos);
      setCargandoPermisos(false);
      setPermisos(resp.permisos);
      setRuta(resp.ruta);
    } catch (error) {
      console.error("Error al obtener permisos:", error);
      if (retries > 0) {
        console.log(`Reintentando... (${3 - retries + 1}/3)`);
        getPermisos(retries - 1);
      } else {
        console.log("Error después de 3 intentos");
        setError("No se pudo obtener los permisos después de varios intentos. Por favor, intente de nuevo más tarde.");
        setCargandoPermisos(false);
      }
    }
  }, [rutas, setCargandoPermisos, camion, setPermisos, setRuta]);

  const handleRetry = () => {
    getPermisos();
  };

  useEffect(() => {
    getPermisos();
  }, [getPermisos]);

  useEffect(() => {
    permisos &&
      setDataT([
        ...permisos.map((tramo) => ({
          tramo_num: tramo.tramo_num,
          tipo_escala: tramo.tipo_escala,
          vehiculo: tramo.vehiculo.tipo,
          tramos: tramo.tramos.map((item) => ({
            dimensiones: item.dimensiones,
            no: item.no,
            permisos: item.permisos,
            puntos: item.puntos,
            tipo: item.tipo,
            tramo: item.tramo,
            show: () => item.show?.(),
          })),
          intersecciones: tramo.intersecciones.map((item) => ({
            dimensiones: item.dimensiones,
            no: item.no,
            permisos: item.permisos,
            puntos: item.puntos,
            tipo: item.tipo,
            tramo: item.tramo,
            show: () => item.show?.(),
          })),
        })),
      ]);
  }, [permisos]);

  const columnsParent = [{ title: "Tramo", dataIndex: "tramo_num" }];

  const camionText = (camion) =>{
    return camion > 0 ? `Camión: ${camiones[camion]}` : `Sin Camión`;
  }

  console.log(datat);

  return (
    <>
      {cargandoPermisos && (
        <Alert message="Cargando permisos, por favor espere..." type="info" showIcon />
      )}
      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          action={
            <Button size="small" type="primary" onClick={handleRetry}>
              Reintentar
            </Button>
          }
        />
      )}
      {permisos && datat && !cargandoPermisos && (
        <Collapse bordered={false} defaultActiveKey={["1"]}>
          {datat.map((tramo, i) => (
            <Panel
              header={`Tramo: ${tramo.tramo_num}. ${getEscala(tramo.tipo_escala)} - Vehículo: ${camionText(tramo.vehiculo)} `}
              key={`${i + 1}_tramos`}
            >
              <TramoTable tramo={tramo} />
            </Panel>
          ))}
        </Collapse>
      )}
    </>
  );
};

export default ContenidoPermisos;
